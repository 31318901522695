import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {

  constructor(
    private userSrvc: UserService
  ) { }

  response;

  changePasswordForm = new UntypedFormGroup({
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
    passwordCheck: new UntypedFormControl('')
  });

  ngOnInit(): void {
    this.changePasswordForm.valueChanges.subscribe(x => {
      if(x.passwordCheck == x.password){
        this.changePasswordForm.controls['passwordCheck'].setErrors(null);
        // this.registrationForm.controls['passwordCheck'].;

      }else {
        this.changePasswordForm.controls['passwordCheck'].setErrors({'incorrect': true});
      }


    })
  }

  changePassword(){
    this.userSrvc.updatePassword(this.changePasswordForm.value)
      .subscribe(res => {
        this.response = res;
      })
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { GlobalService } from '../../../services/global.service';



@Component({
  selector: 'app-edit-find-pair',
  templateUrl: './edit-find-pair.component.html',
  styleUrls: ['./edit-find-pair.component.css']
})
export class EditFindPairComponent implements OnInit {
  @Input() question;
  @Input() answers;

  @Output() updateQuestion = new EventEmitter<any>();
  @Output() updateAnswers = new EventEmitter();

  leftSide = [];
  rightSide = [];

  alpahabet;
  pairIndex = 1;


  constructor(
    private globalSrvc: GlobalService) { }


  ngOnInit(): void {
    if(this.question.options.left_side.length > 0){
      this.leftSide = this.question.options.left_side;
      this.rightSide = this.question.options.right_side;
    }

    this.alpahabet = this.globalSrvc.genCharArray('a', 'z');

  }

  addPair(form){
    if(this.leftSide.length > 0){
      this.pairIndex = this.leftSide[this.leftSide.length-1].key+1;
    }

    this.leftSide.push({key: this.pairIndex, value: ""});
    this.rightSide.push({key: this.alpahabet[this.pairIndex-1], value: ""});

    console.log(this.leftSide, this.rightSide);

    this.createObject();
  }

  deletePair(index){
    this.leftSide.splice(index, 1);
    this.rightSide.splice(index,1);

    this.createObject();
  }

  updateOptions(){
    this.question.options.left_side = this.leftSide;
    this.question.options.right_side = this.rightSide;

    this.updateQuestion.emit(this.question);
    this.updateAnswers.emit(this.answers);

  }


  createObject(){
    let i = 0;
    this.answers = [];
    this.rightSide.forEach(right_element => {

      let currentLeft = this.leftSide[i];
      this.answers.push({right_side: right_element.key, left_side: currentLeft.key});
      i++;
    });

    console.log(this.question);

    this.updateOptions();

  }

  //Image stuff
  onImageChange(e) {
    this.question.material_source = e;
    this.updateQuestion.emit(this.question);
  }


}

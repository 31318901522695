import { Directive, OnInit, ElementRef, Renderer2, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appFillInInput]'
})
export class FillInInputDirective implements OnInit {
  @Input() text;
  @Output() updateEmit = new EventEmitter<any>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit(){
    console.log("Fill in Directive called!");

    let foundPlaces = this.text.split(" ");
    const div = this.renderer.createElement('div');

    foundPlaces.forEach(word => {

      if(word.search("input%")>=0){
        const index = word.split("%")[1];
        const input = this.renderer.createElement('input');

        this.renderer.appendChild(this.el.nativeElement, input);
        this.renderer.listen(input, 'keyup', (event) => {

          this.updateEmit.emit({inputId: index, value: input.value});
        })
      } else{
        const text = this.renderer.createText(word + " ");
        this.renderer.appendChild(this.el.nativeElement, text);
      }

    });
  }
}

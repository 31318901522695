<div class="modal fade" id="resultsDetail" tabindex="-1">
  <div class="modal-fullscreen modal-dialog">
    <div class="modal-content" *ngIf="data">
      <div class="modal-header">
        <h5 class="modal-title"*ngIf="data"  >Výsledky testovania <span *ngIf="data.name">- {{data.name}}</span><span *ngIf="data.class">,{{data.class}}</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body"  >
        <div class="questions mx-auto">
          <div class="question col-lg-6 offset-lg-3" *ngFor="let question of questions">
            <div class="card">
              <h5 class="card-header" *ngIf="question.type != 'fillIn'">{{question.description}} <span class="question-percent">{{data.results[question.id].percent}}%</span></h5>
              <h5 class="card-header" *ngIf="question.type == 'fillIn'">Doplň <span class="question-percent">{{data.results[question.id].percent}}%</span></h5>
              <div class="card-body">
                <!-- <h5 class="card-title">Special title treatment</h5>
                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p> -->
                <app-question-mark-correct *ngIf="question.type == 'markCorrect'" [question]="question" [results]="data.results"></app-question-mark-correct>
                <app-question-fill-in *ngIf="question.type == 'fillIn'" [question]="question" [results]="data.results"></app-question-fill-in>
                <app-question-find-pair *ngIf="question.type == 'findPair'" [question]="question" [results]="data.results"></app-question-find-pair>
                <app-question-find-order *ngIf="question.type == 'findOrder'" [question]="question" [results]="data.results"></app-question-find-order>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="modal-footer">
        <h5 class="overal-score mx-auto">Celkové hodnotenie: {{data.score}}%</h5>
        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
        <button type="button" class="btn btn-primary"  data-bs-dismiss="modal">Zatvoriť</button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import { GlobalService } from '../../../services/global.service';

@Component({
    selector: 'app-question-find-pair',
  templateUrl: './question-find-pair.component.html',
  styleUrls: ['./question-find-pair.component.css']
})
export class QuestionFindPairComponent implements OnInit, OnChanges {
  @Input() question;
  @Input() results;
  @Output() updateEmit = new EventEmitter<any>();

  leftSide;
  rightSide;
  answers = [];

  constructor(private globalSrvc: GlobalService) { }

  ngOnChanges(changes: SimpleChanges) {

    if(this.results && this.rightSide){
      this.updateChanges();
    }

  }

  ngOnInit(): void {

    
    this.leftSide = this.globalSrvc.shuffle(this.question.options.left_side);
    this.rightSide = this.question.options.right_side;
    if(this.results) {
      if(this.results[this.question.id].marked) {
        this.leftSide = this.question.options.right_side.map(r_side => {
          let found =  this.results[this.question.id].marked.find(sides => sides.right == r_side.key)
          return this.question.options.left_side.find(l_side => l_side.key == found.left)
        })  
      }
    }
    
    this.createObject();

    this.updateEmit.emit(this.answers)

    if(this.results && this.rightSide){
      this.updateChanges();
    }

  }

  drop(event: CdkDragDrop<string[]>) {
    // moveItemInArray(this.leftSide, event.previousIndex, event.currentIndex);

    const item_new = this.leftSide[event.previousIndex];
    const item_old = this.leftSide[event.currentIndex];

    this.leftSide[event.currentIndex] = item_new;
    this.leftSide[event.previousIndex] = item_old;
    console.log(event);

    this.answers = [];
    this.createObject();

    this.updateEmit.emit(this.answers)
  }

  createObject(){
    let i = 0;
    this.rightSide.forEach(right_element => {

      let currentLeft = this.leftSide[i]
      this.answers.push({right_side: right_element.key, left_side: currentLeft.key});
      i++;
    });
  }

  updateChanges(){

    const questionResults =  this.results[this.question.id];


    let i = 0;
    this.rightSide.forEach(element => {

        if(questionResults.correct.some(e => e == element.key)){
          this.leftSide[i]["correct"] = "true";
          if(this.leftSide[i].incorrect){
            delete this.leftSide[i].incorrect;
          }
        }else{
          this.leftSide[i]["incorrect"] = "true";
          if(this.leftSide[i].correct){
            delete this.leftSide[i].correct;
          }
        }
        i++;
    });

  }


}

<h3>Nastavenia</h3>


<div class="container mt-5">
  <h5>Zmena hesla</h5>
  <form class="row g-3 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1" [formGroup]="changePasswordForm" autocomplete="off">
    <div class="alert col-12" [innerHTML]="response.message" [ngClass]="{'alert-danger': response.status != 200, 'alert-success': response.status == 200}" role="alert" *ngIf="response">
    </div>
    <div class="col-md-12">
      <label for="inputPassword" class="form-label">Nové heslo:</label>
      <input type="password" class="form-control" id="inputPassword" formControlName="password" required
      [class.is-invalid] = "changePasswordForm.controls['password'].invalid && changePasswordForm.controls['password'].touched"
      [class.is-valid] = "changePasswordForm.controls['password'].valid && changePasswordForm.controls['password'].touched"
      >
      <div class="invalid-feedback" *ngIf="changePasswordForm.controls['password'].invalid && changePasswordForm.controls['password'].touched">
        Zadajte nové heslo: musí obsahovať minimálne 5 znakov.
      </div>

    </div>
    <div class="col-md-12">
      <label for="inputPasswordCheck" class="form-label">Zopakujte heslo:</label>
      <input type="password" class="form-control" id="inputPasswordCheck" formControlName="passwordCheck" required
      [class.is-invalid] = "changePasswordForm.controls['passwordCheck'].invalid && changePasswordForm.controls['passwordCheck'].touched"
      [class.is-valid] = "changePasswordForm.controls['passwordCheck'].valid && changePasswordForm.controls['passwordCheck'].touched"
      >
      <div class="invalid-feedback" *ngIf="changePasswordForm.controls['passwordCheck'].invalid && changePasswordForm.controls['passwordCheck'].touched">
        Heslá sa musia zhodovať.
      </div>

    </div>
    <div class="col-12">
      <button type="submit" class="btn btn-primary" (click)="changePassword()" [disabled]="changePasswordForm.invalid">Zmeniť heslo</button>
    </div>


  </form>
  
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { of, BehaviorSubject, Observable } from 'rxjs';

import { shareReplay, tap, map, catchError, switchMap } from 'rxjs/operators';
import { ApiService } from '@services/api.service'


export type User = {
  id: number;
  username: string;
  email: string;
  role: string;
  groups: [];
  class: string;
}

export class Users {
  data: [User]
}

@Injectable({
  providedIn: 'root'
})



export class UserService {

  private _user: BehaviorSubject<any> = new BehaviorSubject({});
  public readonly user: Observable<User> = this._user.asObservable();


  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private api: ApiService
  ) {
    this.getUserData()
  }


  private apiUrl= this.api.url

  getUserData() {

    this.auth.userEmitter.subscribe(login => {


      if(login.data) {
        let userId = this.auth.decodeCurrentToken().data.id
        console.log("Reading user");

        this.http.get<User>(this.apiUrl+'/users/read_single.php?userId='+userId).subscribe(user => {
          this._user.next(user)
        })
      }else {
        this._user.next({})
      }
    })

  }


  getAllUsers(){
    return this.http.get<Users>(this.apiUrl+'/users/read.php');
  }

  addToGroup(groupId, userId){
    return this.http.post(this.apiUrl+'/users/add_class.php', {class_id: groupId, user_id: userId})
  }

  removeFromGroup(groupId, userId){
    return this.http.delete(this.apiUrl+'/users/remove_class.php?user_id='+userId+"&class_id="+groupId)
  }

  registerUser(userData){
    return this.http.post<{message: string; status: number;}>(this.apiUrl+'/users/register.php', userData);
  }

  registerUsers(usersData){
    return this.http.post<any[]>(this.apiUrl+'/users/register_many.php', usersData);
  }

  updateUser(userData) {
    return this.http.put<{message: string; status: number;}>(this.apiUrl+'/users/update.php', userData);
  }

  updateClasses(users) {
    return this.http.put<any>(this.apiUrl+'/users/update_classes.php', users);
  }

  deleteUser(id) {
    return this.http.delete<{message: string; status: number;}>(this.apiUrl+'/users/delete.php?id=' + id);
  }

  deleteUsers(ids) {
    return this.http.post<any>(this.apiUrl+'/users/delete_many.php', ids);
  }

  updatePassword(paswordData) {
    return this.http.post<{message: string; status: number;}>(this.apiUrl+'/users/update_password.php', paswordData);
  }

  isTeacher() {

    if(this._user.getValue().role == 'teacher' || this._user.getValue().role == 'admin') {
      return true
    }

    return false
  }


}


<div *ngIf="question && answers">
  <form class="input-group mb-3">
    <span class="input-group-text">Otázka: </span>
    <input type="text" class="form-control" name="questionText" placeholder="Zadanie otázky" (change)="updateQuestion.emit(this.question)" [(ngModel)]="question.description">
  </form>

  <!-- Image -->
  <app-image-upload [material_source]="question.material_source" [question_id]="question.id" (updateMaterialSource)="onImageChange($event)"></app-image-upload>


  <form #pairList="ngForm" >
    <div class="input-group" *ngFor="let item of rightSide; let i = index">
      <input type="text" class="form-control" [(name)]="item.key" [(ngModel)]="item.value" (change)="updateOptions(pairList)" required>
      <span class="input-group-text"><b> = </b></span>
      <input type="text" class="form-control" [(name)]="leftSide[i].key" [(ngModel)]="leftSide[i].value" (change)="updateOptions(pairList)"  required>
      <button type="button" class="btn btn-danger" (click)="deletePair(i)">-</button>
    </div>
  </form>
  <div class="d-grid gap-2 mt-2" >
    <button [disabled]="!pairList.valid" type="button" name="button" class="btn btn-success" (click)="addPair(pairList)">+</button>
  </div>
</div>

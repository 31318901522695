import { Component, OnInit, Input } from '@angular/core';
import { FileHandlerService } from "../../services/file-handler.service";
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { PermissionsDirective } from '../../services/global.service';

@Component({
  selector: 'app-lesson-materials',
  templateUrl: './lesson-materials.component.html',
  styleUrls: ['./lesson-materials.component.css']
})
export class LessonMaterialsComponent implements OnInit {

  @Input() lesson;
  materials = [];
  blob;

  currentVideo;

  constructor(
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private fileHandler: FileHandlerService
  ) {

  }

  ngOnInit(): void {


    this.loadMaterials();



  }

  loadVideo(video){
    this.currentVideo = video;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }


  loadMaterials(){
    this.fileHandler.getLessonMaterials(this.lesson.id)
      .subscribe((response: any) => {
        this.materials = response;
        console.log(response);

      })
  }

  addFileToLesson(materialId){
    for(let i = 0; i < this.materials.length; i++){
      if(this.materials[i].id == materialId){
        alert("Tento materiál už lekcia obsahuje!");
          return;
      }
    }

    this.fileHandler.addMaterialToLesson({"material_id": materialId, "lesson_id": this.lesson.id})
      .subscribe(response => {
        alert(response['message']);
        this.loadMaterials();
      })
  }

  removeFileFromLesson(materialId){

    console.log("odstrani sa " + materialId + " z " + this.lesson.id);
    this.fileHandler.removeMaterialFromLesson(this.lesson.id, materialId)
      .subscribe((res: any) => {
        console.log(res);
        this.openSnackBar(res.message, "Zavrieť")
        this.loadMaterials();
      })
  }

  requestFile(materialPath){
    let url = "https://nastuduj.sk/storage/" + materialPath;
    window.open(url, '_blank').focus();
  }



}



<div class="input-group mb-3">
  <span class="input-group-text">Otázka: </span>
  <input type="text" class="form-control" placeholder="Zadanie otázky" (change)="onQuestionChange()" [(ngModel)]="question.description" [ngModelOptions]="{standalone: true}">
</div>
<!-- Image -->
<app-image-upload [material_source]="question.material_source" [question_id]="question.id" (updateMaterialSource)="onImageChange($event)"></app-image-upload>


<form #optionForm="ngForm" (submit)="onAddOption(optionForm)" autocomplete="off">
  <div class="form-check option" *ngFor="let option of question.options; let i = index">
    <input class="form-check-input" type="checkbox" [value]="option.key" (change)="onCheckboxChange($event)" [checked]="checkIfChecked(option.key)">
    <label class="form-check-label">
      {{option.value}} <a class="text-danger" (click)="onRemoveOption(i)">delete</a>
    </label>
  </div>
  <div class="input-group mb-3 w-75">
    <span class="input-group-text">Možnosť {{question.options.length+1}}: </span>
    <input type="text" class="form-control" placeholder="Možnost odpovede" name="value" ngModel>
    <button type="submit" class="btn btn-warning">+</button>

  </div>
</form>

import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})


export class CustomEvaluators {

  constructor() {}

  //Trieda sluzi ako poskytovatel jednotlivych vyhodnocovacich prostriedkov
  //--////--////--////--////--////--////--////--////--////--////--////--////--//
  //--////--////--////--////--////--////--////--////--////--////--////--////--//

  //  testAnswers - correct answers from database
  //  answersData - answers from user

  //Vyhodnotenie najdenie poradia
  evolveFindOrder(question, testAnswers, answersData) {
    const questionResult = {
      "correct": [],
      "incorrect": [],
      "percent": 0
    };

    answersData = answersData[question.id]

    testAnswers.forEach(answerObj => {
      if(answerObj.questionId == question.id){
        testAnswers = answerObj.answers;
      }
    });

    let i = 0;
    answersData.forEach(userAnswer => {
      if(testAnswers[i] == userAnswer.order){
        questionResult.correct.push(userAnswer.order);
      }else{
        questionResult.incorrect.push(userAnswer.order);
      }
      i++;
    });

    //Vyhodnotenie
    // let weight = 100/answersData.length;
    // questionResult.percent = Math.floor(weight * questionResult.correct.length);

    questionResult.percent = questionResult.incorrect.length > 0 ? 0 : 100;
    return questionResult;

  }

  //Vyhodnotenie hladania paru
  evolveFindPair(question, testAnswers, answersData) {
    const questionResult = {
      "correct": [],
      "incorrect": [],
      "marked": [],
      "percent": 0
    };

    answersData = answersData[question.id]


    testAnswers.forEach(answerObj => {
      if(answerObj.questionId == question.id){
        testAnswers = answerObj.answers;
      }
    });

    testAnswers.forEach(correct => {
        let index = answersData.findIndex(x => x.right_side === correct.right_side);
        if(correct.left_side == answersData[index].left_side){
          questionResult.correct.push(correct.right_side)
        }else {
          questionResult.incorrect.push(correct.right_side)
        }
        questionResult.marked.push({left: answersData[index].left_side, right: answersData[index].right_side})
    });

    let weight = 100/answersData.length;
    questionResult.percent = Math.floor(weight * questionResult.correct.length);


    return questionResult;


  }

  //Vyhodnotenie doplnacky
  evolveFillIn(question, testAnswers, answersData) {
    const questionResult = {};
    let questionAnswers = [];
    let correctCount = 0;

    answersData = answersData[question.id]


    testAnswers.forEach(answerObj => {
      if(answerObj.questionId == question.id){
        questionAnswers = answerObj.answers;
      }
    });


    //Prejst cez kazdy input
    questionAnswers.forEach(correctAnswer => {
      const inputResult = {};
      if(!answersData){
        answersData = {};
      }

      if(answersData[correctAnswer.input]) { //Ci uzivatel vyplnil pole
        const userAnswer = answersData[correctAnswer.input];

        const origininalAnswer = userAnswer.value
        const userAnswerValue = userAnswer.value.toString().toLowerCase().trim()
        correctAnswer.value = correctAnswer.value.toString().toLowerCase().trim()
                
        if (userAnswerValue === correctAnswer.value) {
          correctCount++;
          inputResult["result"] = "correct";
          inputResult["correctAnswer"] = origininalAnswer;
          inputResult["userAnswer"] = origininalAnswer;
        } else {
          inputResult["result"] = "incorrect";
          inputResult["correctAnswer"] = correctAnswer.value;
          inputResult["userAnswer"] = origininalAnswer;
        }
        
      } else{
        inputResult["result"] = "missing";
        inputResult["correctAnswer"] = correctAnswer.value;
      }

      questionResult[correctAnswer.input] = inputResult;
    });

    let weight = 100/questionAnswers.length;
    questionResult['percent'] = weight * correctCount;

    return questionResult;
 }

  //Vyhodnotenie zaciarkovacich policok
  evolveMarkCorrect(question, testAnswers, answersData) {
    let insertedAnswers = [];
    let questionAnswers = [];

    const questionResult = {
      "correct": [],
      "incorrect": [],
      "missing": [],
      "percent": 0
    }

    testAnswers.forEach(answerObj => {
      if(answerObj.questionId == question.id){
        questionAnswers = answerObj.answers;
      }
    });


    if(answersData[question.id]){
      insertedAnswers = answersData[question.id];

      questionAnswers.forEach(answer => {
        // Loop cez vsetky spravne odpovede
        let result = insertedAnswers.find(userAnswer => userAnswer == answer);

        if(result){
          //ak bola zaskrtnuta spravna odpoved
          questionResult.correct.push(result);
          let index = insertedAnswers.indexOf(result);
          insertedAnswers.splice(index,1);

        }else {
          //Ak nebola zaskrtnuta spravna odpoved
          questionResult.missing.push(answer)
        }
      });

      questionResult.incorrect = insertedAnswers;

    }else{
      // console.warn("Pre otazku ID: " + question.id + " neboli zaskrtnute ziadne odpovede");
      questionResult.missing = questionAnswers;
    }

    let weight = 100/questionAnswers.length;
    questionResult.percent = weight * questionResult.correct.length;
    if(questionResult.incorrect.length > 0) questionResult.percent = 0

    return questionResult;
  }



}

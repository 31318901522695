import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {ScrollingModule} from '@angular/cdk/scrolling';

import { FileHandlerService } from '../services/file-handler.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnInit {

  @Input() subjectId;
  @Output() addFile = new EventEmitter();
  @Output() onClose = new EventEmitter();

  newFile;

  videoUrl = new UntypedFormControl('');


  materials = [];
  marked = 0;
  filterQuery = "";
  showSubjectOnly = true;

  addFormSwitch = false;
  uploading = false;
  hasFile = false;
  constructor(
    private fileHandler: FileHandlerService
  ) { }

  ngOnInit(): void {
    this.loadMaterials();
  }

  loadMaterials(){
    this.fileHandler.getMaterials()
      .subscribe(response => {
        this.materials = response;

        this.materials
          .forEach(mat => {
            if(mat.type == 'video'){
              mat.thumbnail = "https://img.youtube.com/vi/"+this.fileHandler.getVideoId(mat.path)+"/maxresdefault.jpg";
            }
            else if(mat.type == "pdf" || mat.type =="application/pdf"){
              mat.thumbnail = "assets/imgs/pdf_file.png";
            }
            else if(mat.type == "doc" || mat.type == "docx"){
              mat.thumbnail = "assets/imgs/word_file.png";
            }
            else if(mat.type == "ppt" || mat.type == "pptx"){
              mat.thumbnail = "assets/imgs/ppt_file.png";
            }
            else if(mat.type == "xls" || mat.type == "xlsx"){
              mat.thumbnail = "assets/imgs/excel_file.png";
            }
            else{
              mat.thumbnail = "assets/imgs/other_file.png";
            }
          });



      })
  }

  markMaterial(id){
    this.marked = id;
  }

  addToLesson(){
    this.addFile.emit(this.marked);
  }



  fileBrowseHandler(event){
    const fileList: FileList = event.target.files;

    //check whether file is selected or not
    if (fileList.length > 0) {
      let file = fileList[0];
      this.hasFile = true;
      this.newFile = {file: file, name: file.name, type: file.type, tz: new Date().toISOString(), subject_id: this.subjectId}
      //get file information such as name, size and type

    } else {
      alert("Naraz môžete nahrať iba jeden súbor!");
    }
  }

  uploadFile(){
    console.log(this.newFile);
    let formData = new FormData();

    formData.append('file', this.newFile.file, this.newFile.name);

    this.fileHandler.upload_material(formData)
      .subscribe((res: any) => {
        console.log(res);

        if(res.status == "1"){
          this.newFile['filepath'] = res.filename;
          delete this.newFile['file'];

          console.log(this.newFile);

          this.fileHandler.createMaterial(this.newFile)
            .subscribe(response => {
              this.addFormSwitch=false;
              this.uploading = false;
              this.hasFile = false;

              this.loadMaterials();

              alert(res.message);
            });
        }
    }, (err) => {
          console.log(err);
    });

  }

  uploadVideo(){
    this.newFile = {filepath: this.videoUrl.value, type: 'video', name: "Video", tz: new Date().toISOString(), subject_id: this.subjectId}

    this.fileHandler.createMaterial(this.newFile)
      .subscribe(response => {
        this.addFormSwitch=false;
        this.uploading = false;
        this.hasFile = false;
        this.videoUrl.reset();
        this.loadMaterials();
        console.log(response);
      });

  }

  deleteMaterial(materialId){
    this.fileHandler.deleteMaterial(materialId)
      .subscribe(res => {
        console.log(res);
        this.loadMaterials();
      })
  }




}

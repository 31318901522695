<div class="modal fade" tabindex="-1" id="userDetail">
  <div class="modal-dialog" >
    <div class="modal-content" *ngIf="user">
      <div class="modal-header">
        <h5 class="modal-title">{{user.username}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <label >Opravnenie k triedam: <span *ngFor="let class of user.groups">{{class.name}} </span></label>
        <form>
          <div class="form-check" *ngFor="let class of classes; let i = index">

            <input [checked]="isChecked(class)" class="form-check-input" type="checkbox" [value]="i" (change)="onCheckChange($event.target)">
            <label class="form-check-label">
              {{class.name}}
            </label>
          </div>
        </form>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zavrieť</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>

<app-file-manager [subjectId]="lesson.subjectId" (addFile)="addFileToLesson($event)" (onClose)="loadMaterials()"></app-file-manager>

<!-- <app-video-player [video]="currentVideo"></app-video-player> -->

<div *ngIf="!materials.length">
  K tejto lekcií zatiaľ neboli pridané žiadne materiály.
  <button type="button" class="btn btn-success" name="button" data-bs-toggle="modal" data-bs-target="#fileManager" *grantPermissions="'admin teacher'" >Pridať materiál +</button>
</div>

<div *ngIf="materials.length > 0">
  <table class="table table-hover" >
    <thead>
      <tr>
        <th scope="col" *grantPermissions="'admin teacher'">#</th>
        <th scope="col">Názov</th>
        <th scope="col">Typ</th>
        <th scope="col">Dátum pridania</th>
      </tr>
    </thead>
    <tbody>
      <tr class="material-row" *ngFor="let material of materials" title="{{material.description}}">
        <th *grantPermissions="'admin teacher'"><i class="bi bi-trash remove-button" (click)="removeFileFromLesson(material.id)"></i></th>
        <!-- <td *ngIf="material.type == 'video'"><a (click)="loadVideo(material)"  data-bs-toggle="modal" data-bs-target="#videoPlayer">{{material.name}}</a></td> -->
        <td *ngIf="material.type == 'video'"><a href="{{material.path}}" target="_blank">{{material.name}}</a></td>
        <td *ngIf="material.type != 'video'" ><a href="https://nastuduj.sk/storage/{{material.path}}" target="_blank">{{material.name}}</a></td>
        <td>{{material.type}}</td>
        <td>{{material.uploaded}}</td>
      </tr>
      <tr>
        <td colspan="4" style="padding: 0; border-bottom: 0;">

        </td>
     </tr>
    </tbody>
  </table>

  <div class="d-grid col-6 mx-auto" *grantPermissions="'admin teacher'">
    <button data-bs-toggle="modal" data-bs-target="#fileManager" class="btn btn-success" type="button" name="button">+</button>
  </div>
</div>

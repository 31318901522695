import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { of, Observable } from 'rxjs';
import { shareReplay, tap, map, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserAccessGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    return this.auth.validateToken()
      .pipe(
        map(res => {
          // console.log(res);
          if(res.code == 200){
            return true;
          }else {
            this.auth.logout("Platnosť vášho prihlásenia vypršala! Prosím prihláste sa znova :)");
          }
        })
      )
  }

}

<app-result-detail [data]="currentData"></app-result-detail>

<div class="fs-5" *ngIf="lessonId">
    <a [routerLink]="['/subjects', subjectId, lessonId]"><i class="bi bi-chevron-left"></i> spať k lekcii</a>
</div>


<h2  class="text-center" *ngIf="testData">Výsledky testu <b>{{testData.name}}</b></h2>

<nav *grantPermissions="'admin', 'teacher'" class="navbar navbar-expand-lg navbar-light bg-light">
  <form class="container-fluid form-inline">
    <div class="d-flex">
      <label for="nameInput" class="form-label me-2" >Meno:</label>
      <input id="nameInput" class="form-control" type="search" placeholder="Meno" (keyup)="searchByName($event)" [(ngModel)]="filterQuery" [ngModelOptions]="{standalone: true}">
    </div>
    <div class="d-flex ms-auto">
      <label for="yearsSelect" class="form-label me-2" >Rok:</label>
      <select class="form-select" id="yearsSelect" name="yearsSelect" (change)="changeYear($event.target.value)">
        <option value='all' selected>Všetky</option>
        <option *ngFor="let year of years" [value]="year">{{year}}</option>
      </select>
    </div>
    <div class="d-flex ms-2">
      <label for="classSelect" class="form-label me-2" >Trieda:</label>
      <select class="form-select" id="classSelect" name="classSelect" (change)="changeClass($event)">
        <option value='all' selected>Všetky</option>
        <option *ngFor="let class of classes" [value]="class">{{class}}</option>
      </select>
    </div>
  </form>
</nav>


<div class="data-table">
  <table class="table table-sm table-hover">
    <thead>
      <tr>
        <th scope="col">
          <div class="form-check">
            <input class="form-check-input" [checked]="masterChecked" style='margin-left: -0.5em' id="master" (click)="updateChecked($event)" type="checkbox" >
          </div>
        </th>
        <th scope="col">Meno</th>
        <th scope="col">Trieda</th>
        <th scope="col" class="clickable" (click)="sortResults('time_begin')">
          Začiatok
          <ng-container *ngIf="sortParam == 'time_begin'">
            <i class="bi bi-sort-down" *ngIf='sortDirection=="desc"'></i>
            <i class="bi bi-sort-up" *ngIf='sortDirection=="asc"'></i>
          </ng-container>
        </th>
        <th scope="col" class="clickable" (click)="sortResults('time')">
          Čas
          <ng-container *ngIf="sortParam == 'time'">
            <i class="bi bi-sort-down" *ngIf='sortDirection=="desc"'></i>
            <i class="bi bi-sort-up" *ngIf='sortDirection=="asc"'></i>
          </ng-container>
        </th>
        <th scope="col" class="clickable" (click)="sortResults('score')">
          Výsledok
          <ng-container *ngIf="sortParam == 'score'">
            <i class="bi bi-sort-down" *ngIf='sortDirection=="desc"'></i>
            <i class="bi bi-sort-up" *ngIf='sortDirection=="asc"'></i>
          </ng-container>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="results">
      <tr *ngFor="let result of toShowResults; let i = index">
        <th scope="row">
          <div class="form-check">
            <input class="form-check-input" [checked]="result.checked" (click)="updateChecked($event)" id="{{result.id}}" style='margin-left: -0.5em' type="checkbox" value="">
          </div>
        </th>
        <td *ngIf="result.results" (click)="showResults(result)" class="name" data-bs-toggle="modal"
        data-bs-target="#resultsDetail">{{result.name}}</td>
        <td *ngIf="!result.results">{{result.name}}</td>
        <td>{{result.class}}</td>
        <td>{{result.time_begin_transformed}}</td>
        <td>{{result.time}}</td>
        <td>{{result.score}}%</td>
      </tr>
    </tbody>
    <tbody>
      <tr *ngIf="!results">
        <td colspan="6">Žiadne výsledky</td>
      </tr>
    </tbody>
    <tfoot *ngIf="results">
      <tr style="border: 0" *ngIf="results.length > 9">
        <td colspan="4" style="">
          <ul class="pagination">
            <li class="page-item">
              <button class="page-link" [disabled]="pagination.activePage == 0"
               (click)="updatePage(pagination.activePage-1)" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </button>
            </li>
            <li class="page-item" [class.active]="page.active" *ngFor="let page of pagination.pages; let i = index"><a class="page-link" (click)="updatePage(i)">{{i+1}}</a></li>
            <li class="page-item">
              <button class="page-link" [disabled]="pagination.activePage+1 == pagination.pages.length"
              (click)="updatePage(pagination.activePage+1)" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </button>
            </li>
          </ul>
        </td>
        <td>
          <label for="resultsSize" class="form-label me-2" >Celkom výsledkov: {{toShowResults.length}}</label>
        </td>
        <td>
          <select class="form-select" id="resultsSize" name="resultsSize" (change)="updateResultsSize($event)">
            <option value='10' selected>10</option>
            <option value='25'>25</option>
            <option value='50'>50</option>
          </select>
        </td>
      </tr>

    </tfoot>
  </table>
</div>

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { UserService } from '../../services/user.service';
import { SubjectsService } from '../../services/subjects.service';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  constructor(
    private userSrvc: UserService,
    private subjectsSrvc: SubjectsService,
    private papa: Papa
  ) { }


  users;
  markedUser;

  classes = [];
  userEditorForm: UntypedFormGroup;



  ngOnInit(): void {
    this.loadUsers()
    this.loadClasses();
  }

  loadUsers() {
    this.userSrvc.getAllUsers().subscribe(
      results => {
        console.log(results);
        this.users = results.data;

        this.users.forEach(user => {
          if(!user.class) {
            user.class = 0
          }
        });
      }
    );
  }

  loadClasses() {
    this.subjectsSrvc.getClasses().subscribe(
      classes => this.classes = classes
    )
  }

  loadUserEditor(data: any = null) {




    this.userEditorForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      first_name: new UntypedFormControl(''),
      last_name: new UntypedFormControl(''),
      password: new UntypedFormControl(),
      email: new UntypedFormControl(''),
      role: new UntypedFormControl(''),
      classId: new UntypedFormControl('0')
    })

    if(data) {
      data.first_name = data.username.split(' ')[0]
      data.last_name = data.username.split(' ')[1] ? data.username.split(' ')[1] : data.username.split(' ')[0]

      if(data.first_name == data.last_name) {data.first_name = ''}

      this.userEditorForm.patchValue(data)
    } else {
      this.userEditorForm.patchValue({password: (Math.random() + 1).toString(36).substring(7).toUpperCase()})
    }
  }


  generateRandomPassword() {
    this.userEditorForm.patchValue({password: (Math.random() + 1).toString(36).substring(7).toUpperCase()})
  }

  updateUser() {

    const userData = this.userEditorForm.value

    if(userData.password == '') { delete userData.password}

    this.userSrvc.updateUser(userData).subscribe(
      res => {
        this.userSrvc.getAllUsers().subscribe(
          results => {
            this.users = results.data;
            this.userEditorForm=null
            alert(res.message)
            this.loadUsers()
          }
        );
      }
    )
  }

  createUser() {
    const userData = this.userEditorForm.value

    this.userSrvc.registerUser(userData).subscribe(
      res => {
        this.userSrvc.getAllUsers().subscribe(
          results => {
            this.users = results.data;
            this.userEditorForm=null
            alert(res.message)
          }
        );
      }
    )
  }

  deleteUser() {
    if(!confirm("Určite chcete vymazať tohoto používateľa?")) {return}

    const userData = this.userEditorForm.value

    this.userSrvc.deleteUser(userData.id).subscribe(
      res => {
        this.userSrvc.getAllUsers().subscribe(
          results => {
            this.users = results.data;
            this.userEditorForm=null
            alert(res.message)
          }
        );
      }
    )
  }

  searchString = ''
  classSelector = ''
  tableFilter() {

      let s = this.searchString.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")
      let classSelector = this.classSelector

      if(!s && classSelector == '') {return this.users}

      return this.users.filter(
          user => {
            if(classSelector == '') return user

            if(user.class == classSelector) return user

            return
          }
      )
      .filter(
        user => {
          if(!s) return user

          if(user.username.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").indexOf(s) !== -1) {
            return user
          } else if(user.id == s) {
            return user
          }  else if(user.email.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").indexOf(s) !== -1) {
            return user
          }

          return
        })
    }

  selectedUsers = []
  updateSelectedList(userId: number, checked: boolean = false) {

    if(userId == 0) {

      this.tableFilter().forEach(user => {

        const listIndex = this.selectedUsers.findIndex(id => id == user.id);

        if(checked) {
          if(listIndex == -1) this.selectedUsers.push(user.id)
        } else {
          if(listIndex > -1) this.selectedUsers.splice(listIndex, 1)
        }

      });
      return
    }

    const listIndex = this.selectedUsers.findIndex(id => id == userId);
    if(listIndex >= 0) {
      this.selectedUsers.splice(listIndex, 1);
      return
    }

    this.selectedUsers.push(userId)

  }

  inList(userId: number) {
    if(this.selectedUsers.includes(userId)) return true
    return false
  }

  allSelected() {

    return this.tableFilter().every(user => this.selectedUsers.includes(user.id))
  }

  deleteMany() {

    if(!confirm("Určite chcete vymazať označených užívateľov? Zmeny sú nevratné!")) return

    this.userSrvc.deleteUsers({ids: this.selectedUsers}).subscribe(
      res => {
        alert(res.message)
        this.loadUsers();
        this.selectedUsers = []
      }
    )

  }

  newClassSelector = '';
  updateClasses() {

    if(!confirm("Určite chcete zmeniť triedu označených užívateľov?")) return

    const users = this.users.filter(user => {
      if(this.selectedUsers.includes(user.id)) {
        user.classId = this.newClassSelector
        return true
      }
      return false
    })

    this.userSrvc.updateClasses({users: users}).subscribe(
      res => {
        this.loadUsers();
      }
    )

  }


  @ViewChild('takeInput', {static: false})
  InputVar: ElementRef;

  importedUsers = []
  notFounClasses = []
  conflictUsers = []
  // Import
  onFileChanged(event: any) {
    const file = event.target.files[0]
    console.log(file);

    if(!file) return

    let options = {
      encoding: 'utf-8',
      delimiter: ',',
      complete: (results, file) => {
          const rows = results.data
          rows.splice(0,1);

          rows.forEach(row => {
            if(row.length < 4) {return}
            const userClass = this.classes.find(c => c.name == row[1])
            if (!userClass && row[1]) {
              if(!this.notFounClasses.includes(row[1])) this.notFounClasses.push(row[1])
            }

            if(this.users.some(u => u.email == row[2])) {
              this.conflictUsers.push(row[2])
            } else {
              this.importedUsers.push({
                first_name: row[0].split(' ')[1],
                last_name: row[0].split(' ')[0],
                classId: userClass ? userClass.id : 0,
                class: userClass ? userClass.name : 0,
                email: row[2],
                password: row[3],
                role: 'student'
              })
            }

          });

          console.log(this.importedUsers);
        }
        // Add your options here
    };


    this.papa.parse(file,options);


  }

  cancelImport() {
    this.InputVar.nativeElement.value = "";
    this.importedUsers = []
    this.notFounClasses = []
    this.conflictUsers = []
  }

  importUsers() {

    this.userSrvc.registerUsers({users: this.importedUsers}).subscribe(
      res => {

        if(res.every(r => r.result)) {
          alert("Import prebehol úspešne!")
        }

        this.loadUsers();
      }
    )

    this.cancelImport()
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders ,HttpParams } from '@angular/common/http';
import { ApiService } from '@services/api.service'

@Injectable({
  providedIn: 'root'
})
export class FileHandlerService {
  private apiUrl= this.api.url

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    }),
  };

  public fileHttpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  }

  constructor(
    private http: HttpClient,
    private api: ApiService
  ) { }

  upload_img(file){
    return this.http.post('https://nastuduj.sk/storage/upload_image.php', file)
  }

  upload_material(file){
    return this.http.post('https://nastuduj.sk/storage/upload_material.php', file)
  }

  createMaterial(file) {
    return this.http.post(this.apiUrl+'/lessons/materials/create.php', file)
  }

  deleteMaterial(materialId) {
    return this.http.delete(this.apiUrl+'/lessons/materials/delete.php?materialId='+materialId);
  }

  getLessonMaterials(lessonId){
    return this.http.get<any>(this.apiUrl+'/lessons/materials/read_lesson.php?id='+lessonId);
  }

  getMaterials() {
    return this.http.get<any>(this.apiUrl+'/lessons/materials/read.php');
  }

  addMaterialToLesson(values){
    return this.http.post(this.apiUrl+"/lessons/materials/add_to_lesson.php",
      values, this.httpOptions)
  }

  removeMaterialFromLesson(lessonId, materialId){
    let httpParams = new HttpParams().set('lessonId', lessonId).set('materialId', materialId);
    this.httpOptions["params"] = httpParams;

    return this.http.delete(this.apiUrl+"/lessons/materials/remove_from_lesson.php", this.httpOptions);
  }

  requestFile(fileId){
    return this.http.get<any>(this.apiUrl+"/lessons/materials/open_file.php?id="+fileId, this.fileHttpOptions);
  }

  getVideoId(videoUrl){
    let urlArr = videoUrl.split('/');
    urlArr = urlArr[this.last(urlArr)].split('=');

    return urlArr[this.last(urlArr)];
  }

  private last(array){
    return array.length-1;
  }
}

<h3 class="mb-5">Pre prístup do systému sa prosím prihláste. </h3>


<div *ngIf="message" class="alert alert-primary" role="alert">
  {{message}}
</div>


<div class="row justify-content-md-center">
<form [formGroup]="form" class="col-6 col-offset-3">
  <div class="mb-3" >
    <label for="InputEmail" class="form-label">Email</label>
    <input type="email" class="form-control" id="InputEmail" name="email" formControlName="email">
  </div>
  <div class="mb-3">
    <label for="InputPassword" class="form-label">Heslo</label>
    <input type="password" class="form-control" id="InputPassword" name="password" formControlName="password">
  </div>
  <!-- <div class="form-text mb-3" >
    Ak ešte nemáte svoj účet vytvorte si ho <a href="/register">kliknutím sem</a>!
  </div> -->
  <button (click)="login()" class="btn btn-primary">Prihlásiť sa</button>
</form>
</div>

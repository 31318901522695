import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';

import { SubjectsService } from '@services/subjects.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LessonNameResolver implements Resolve<string> {
  constructor(
    private subjectsSrvc: SubjectsService
  ){}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    return this.subjectsSrvc.getSingleLesson(route.params.lessonId)
    .pipe(
      map(res => {
        return res.name
      })
    )
  }
}

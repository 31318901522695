import { Directive, OnInit, OnDestroy, Input, ViewContainerRef, TemplateRef } from '@angular/core';
import { UserService } from '../user.service';
import { AuthService } from '../auth.service';


@Directive({
  selector: '[grantPermissions]'
})
export class PermissionsDirective implements OnInit, OnDestroy {

  @Input('grantPermissions') allowedRoles: string;



  constructor(
    private userSrvc: UserService,
    private auth: AuthService,
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>
  ) { }

  ngOnInit() {


    this.updateState();
    // this.auth.userEmitter.subscribe(login => {
    //   if(login.data) {
    //
    //   } else {
    //     if(!isGuest){
    //       this.viewContainer.clear();
    //     }
    //   }
    // });


  }


  updateState() {
    let isGuest = this.allowedRoles.split(" ").includes('guest');


    this.userSrvc.user.subscribe(user => {

      if(user.email){
        let allowedRolesArr = this.allowedRoles.split(" ");

        if(allowedRolesArr.includes(user.role) || this.allowedRoles == "allUsers") {
          this.viewContainer.createEmbeddedView(this.template);
        }
        else {
          this.viewContainer.clear();
        }
      }
      else if(isGuest) {
        this.viewContainer.createEmbeddedView(this.template);
      }else {
        this.viewContainer.clear();  
      }
    })
  }

  ngOnDestroy() {
  }

}

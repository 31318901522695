import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-edit-find-order',
  templateUrl: './edit-find-order.component.html',
  styleUrls: ['./edit-find-order.component.css']
})
export class EditFindOrderComponent implements OnInit {
  @Input() question;
  @Input() answers;

  @Output() updateQuestion = new EventEmitter<any>();
  @Output() updateAnswers = new EventEmitter();

  orderList = [];

  constructor() { }

  ngOnInit(): void {


    this.question.options.forEach(el => {
      this.orderList.push(el.value);
    })
  }

  addItem(form) {
    this.orderList.push(form.value.newItem);
    this.updateOptions();

  }

  deleteItem(index) {
    this.orderList.splice(index, 1);
    this.updateOptions();
  }

  updateOptions(){
    this.question.options = [];
    this.answers = [];
    let x =1;
    this.orderList.forEach(element => {
      this.question.options.push({order: x, value: element});
      this.answers.push(x);
      x++;
    });

    this.updateQuestion.emit(this.question);
    this.updateAnswers.emit(this.answers);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.orderList, event.previousIndex, event.currentIndex);

    this.updateOptions();

    // this.updateEmit.emit(this.orderList);
  }

  //Image stuff
  onImageChange(e) {
    this.question.material_source = e;
    this.updateQuestion.emit(this.question);
  }

}

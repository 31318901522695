

<div class="row mw-75">
  <div class="col-5 row-item" style="padding: 0;">
    <div class="left-item" *ngFor="let itemRight of rightSide">
      {{itemRight.value}}
    </div>

  </div>
  <div class="col-2 row-item" style="padding: 0;">
    <div class="middle-symbol fs-4" *ngFor="let itemLeft of leftSide">
      <i class="bi bi-arrow-right"></i>

    </div>
  </div>

  <div cdkDropList class="example-list row-item col-5" (cdkDropListDropped)="drop($event)" style="padding: 0">
    <div class="example-box" *ngFor="let item of leftSide" [class.correct]="item.correct" [class.incorrect]="item.incorrect" cdkDrag [cdkDragDisabled]="results">
      {{item.value}}
    </div>
  </div>

</div>


<!--

<div class="row mw-75" *ngFor="let itemRight of rightSide; let i = index">
  <div class="col-sm-5" style="padding: 0; text-aling:right">
    <div class="left-item" >
      {{itemRight.value}}
    </div>

  </div>
  <div class="col-sm-2" style="padding: 0;">
    <div class="middle-symbol">
      =
    </div>
  </div>

  <div cdkDropList class="example-list col-sm-5" (cdkDropListDropped)="drop($event)" style="padding: 0">
    <div class="example-box" [class.correct]="leftSide[i].correct" [class.incorrect]="leftSide[i].incorrect" cdkDrag [cdkDragDisabled]="results">
      {{leftSide[i].value}}</div>
  </div>

</div> -->

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormArray, Validators, UntypedFormGroup  } from "@angular/forms";


@Component({
  selector: 'app-edit-mark-correct',
  templateUrl: './edit-mark-correct.component.html',
  styleUrls: ['./edit-mark-correct.component.css']
})
export class EditMarkCorrectComponent implements OnInit {

  @Input() question;
  @Input() testAnswers;

  @Output() updateQuestion = new EventEmitter<any>();
  @Output() updateAnswers = new EventEmitter();

  option_template = {"key": "", "value": ""};
  options_index = 1;
  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder

  ) {
    this.form = this.formBuilder.group(
      {
      answers: this.formBuilder.array([], [Validators.required])
    })
  }

  ngOnInit(): void {
    if(this.question.options.length > 0){
      this.options_index = parseInt(this.question.options[this.question.options.length-1].key)+1;
    }

    const answer: UntypedFormArray = this.form.get('answers') as UntypedFormArray;


    this.testAnswers.forEach(element => {
      answer.push(new UntypedFormControl(element))



      //########### undeletede answers finder
      // if(this.question.options.find(x => x.key == element)){
      //   console.log(true);
      // } else {
      //   console.error(this.question.id);
      //   console.warn(element, this.question.options);
      // }

    });





  }

  onAddOption(optionForm){
    const user_input_control = optionForm.form.controls.value;
    const new_option = this.option_template;
    new_option.key = String(this.options_index);
    this.options_index++;
    new_option.value = user_input_control.value;

    this.question.options.push(JSON.parse(JSON.stringify(new_option)));
    optionForm.resetForm();

    this.onQuestionChange();
  }

  onRemoveOption(index) {
    console.log("Removed");
    console.log(this.question.options);
    console.log(index);


    const answer: UntypedFormArray = this.form.get('answers') as UntypedFormArray;


    const ind = answer.controls.findIndex(x => x.value == this.question.options[index].key);
    console.log(ind);

    if(ind > -1){
      answer.removeAt(ind);
    }

    this.question.options.splice(index,1);


    console.log(answer.controls);

    this.updateAnswers.emit(answer.value);
    this.onQuestionChange();
  }

  onCheckboxChange(e) {
    const answer: UntypedFormArray = this.form.get('answers') as UntypedFormArray;
    if (e.target.checked) {
      answer.push(new UntypedFormControl(e.target.value));
    } else {
       const index = answer.controls.findIndex(x => x.value === e.target.value);
       answer.removeAt(index);
    }

    this.updateAnswers.emit(answer.value);
  }

  onQuestionChange() {
    console.log(this.question);

    this.updateQuestion.emit(this.question);
  }

  checkIfChecked(key){
    return this.testAnswers.some(el => el == key);
  }


  //Image stuff
  onImageChange(e) {
    this.question.material_source = e;
    this.updateQuestion.emit(this.question);
  }


}

import { Component, OnInit, Input, Output, EventEmitter, ElementRef, Renderer2,
  ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormArray, Validators, FormGroup  } from "@angular/forms";


@Component({
  selector: 'app-question-fill-in',
  templateUrl: './question-fill-in.component.html',
  styleUrls: ['./question-fill-in.component.css']
})
export class QuestionFillInComponent implements OnInit, OnChanges {

  @Input() question;
  @Input() results;
  @Output() updateEmit = new EventEmitter<any>();
  inputList = [];
  answers = {};

  constructor(private el: ElementRef,  private renderer: Renderer2) {  }



  ngOnChanges(changes: SimpleChanges) {
    if(this.results){
      if(changes.results.currentValue){
        const inputsResults = changes.results.currentValue[this.question.id];
        this.updateChanges(inputsResults);
      }
    }
  }


  ngOnInit(): void {

    let foundPlaces = this.question.questionText.split(" ");


    const div = this.renderer.createElement('div');
    this.renderer.addClass(div, "form-group")
    this.renderer.appendChild(this.el.nativeElement.childNodes[0], div);

    foundPlaces.forEach(word => {
      if(word.search("input%")>=0){
        const index = word.split("%")[1];
        const input = this.renderer.createElement('input');

        this.renderer.addClass(input, 'form-control');
        this.renderer.addClass(input, 'form-control-sm');
        this.renderer.addClass(input, 'mx-2');
        this.renderer.setAttribute(input, 'id', index);
        this.inputList.push(input);

        this.renderer.appendChild(div, input);

        this.renderer.listen(input, 'keyup', (event) => {
          this.answers[index] = {inputId: index, value: input.value};
          this.updateEmit.emit(this.answers);
        })
      } else{
        const text = this.renderer.createText(word + " ");
        this.renderer.appendChild(div, text);
      }

    });

    if(this.results){
      this.updateChanges(this.results[this.question.id])
    }


  }


  updateChanges(inputsResults){

    if(inputsResults){
      this.inputList.forEach(input => {
        const inputResult = inputsResults[input.id];
        // console.log(inputsResults);

        this.renderer.removeClass(input, "missing");
        this.renderer.removeClass(input, "correct");
        this.renderer.removeClass(input, "incorrect");



        this.renderer.addClass(input, inputResult.result);
        this.renderer.setAttribute(input, 'disabled', 'disabled');

        if(inputResult.result == "incorrect" || inputResult.result == "missing"){

          if(inputResult.userAnswer){
            this.renderer.setAttribute(input, 'value', inputResult.userAnswer);
          }

          const correctSpan = this.renderer.createElement('span');
          const correctText = this.renderer.createText(inputResult.correctAnswer);


          this.renderer.addClass(correctSpan, 'correct-answer');
          this.renderer.insertBefore(this.renderer.parentNode(input), correctSpan, input);
          this.renderer.appendChild(correctSpan, correctText);


        }else{
          this.renderer.setAttribute(input, 'value', inputResult.correctAnswer);

          if(inputResult.userAnswer) {
            if(inputResult.userAnswer.endsWith(' ')) {
              const mark = this.renderer.createText('*')
              this.renderer.insertBefore(this.renderer.parentNode(input), mark, input)    
            }
          }
          
        }
      });
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@services/auth.service';

import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {
    form:UntypedFormGroup;
    message;

    constructor(private fb:UntypedFormBuilder,
                 private authService: AuthService,
                 private router: Router,
                 private route: ActivatedRoute) {

        this.form = this.fb.group({
            email: ['',Validators.required],
            password: ['',Validators.required]
        });
    }

    ngOnInit() {
      this.message = "";
      this.route.queryParams.subscribe(params => {
        console.log(params);

        this.message = params['reason'];
      });
    }


    login() {
        const val = this.form.value;

        if (val.email && val.password) {
            this.authService.login(val.email, val.password)
                .subscribe(
                  x => {},
                  err => this.router.navigateByUrl('/login?reason=Chyba pripojenia!'),
                  () => {}
                );
        }
    }
}

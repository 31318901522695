

<h3>Používatelia</h3>


<div class="data-table row">


  <div id="userEditorForm" class="my-5 offset-lg-1 col-lg-10 section p-5" *ngIf="userEditorForm" >

    <h5 *ngIf="userEditorForm.value.id" >{{userEditorForm.value.first_name}} {{userEditorForm.value.last_name}}</h5>
    <h5 *ngIf="!userEditorForm.value.id">Nový užívateľ</h5>
    <form class="row gy-3" [formGroup]="userEditorForm">
      <div class="col-lg-6 col-xl-4">
        <label class="form-label">Meno:</label>
        <input type="text" class="form-control" formControlName="first_name">
      </div>
      <div class="col-lg-6 col-xl-4">
        <label class="form-label">Priezvisko:</label>
        <input type="text" class="form-control" formControlName="last_name">
      </div>
      <div class="col-lg-6 col-xl-4">
        <label class="form-label">Email:</label>
        <input type="text" class="form-control" formControlName="email">
      </div>
      <div class="col-lg-6 col-xl-4">
        <label class="form-label">Rola:</label>
        <select class="form-select" formControlName="role">
          <option value="" selected hidden disabled>Vyberte rolu...</option>
          <option value="student">Študent</option>
          <option value="teacher">Učiteľ</option>
          <option value="admin">Admin</option>
        </select>
      </div>
      <div class="col-lg-6 col-xl-4">
        <label class="form-label">Trieda:</label>
        <select class="form-select" formControlName="classId">
          <option value="0" selected >Žiadna trieda</option>
          <option *ngFor='let class of classes' [value]="class.id">{{class.name}}</option>
        </select>
      </div>
      <div class="col-lg-6 col-xl-4">
        <label class="form-label">Heslo:</label>
        <div class="input-group">
          <input type="text" class="form-control" formControlName="password">
          <button class="input-group-text btn btn-secondary" (click)="generateRandomPassword()">generovať</button>
          <i class="text-muted" *ngIf="userEditorForm.value.id">Ak si neželáte zmeniť heslo nechajte toto pole prázdne...</i>
        </div>
      </div>
      <div class="mt-4 ">
        <button type="button" class="btn btn-secondary ms-auto" (click)="userEditorForm=null">Zavrieť</button>
        <button *ngIf="userEditorForm.value.id"type="button" class="btn btn-secondary ms-3 " (click)="deleteUser()">Vymazať</button>
        <button *ngIf="userEditorForm.value.id" type="button" class="btn btn-primary ms-3 " (click)="updateUser()">Uložiť</button>
        <button *ngIf="!userEditorForm.value.id"type="button" class="btn btn-primary ms-3 " (click)="createUser()">Vytvoriť</button>

      </div>
    </form>
  </div>

  <!-- Import -->
  <div class="my-5 offset-lg-1 col-lg-10 section p-5" *ngIf="importedUsers.length > 0 || conflictUsers.length > 0">

    <h5>Import študentov</h5>

    <p>Je pripravených <b>{{importedUsers.length}}</b> študentov na import.</p>

    <span class="text-muted" *ngFor="let class of notFounClasses"><br>Trieda <b>{{class}}</b> nebola nájdená! Prosím vytvorte ju pred importom!</span>
    <span class="text-muted" *ngFor="let user of conflictUsers"><br>Užívateľ s loginom <b>{{user}}</b> už existuje a preto bude vynechaný z importu!</span>

    <div class="d-flex mt-4">
      <button type="button" name="button" class="btn btn-secondary ms-auto" (click)="cancelImport()">Zrušiť</button>
      <button type="button" name="button" class="btn btn-primary ms-3" [disabled]="notFounClasses.length > 0 || importedUsers == 0" (click)="importUsers()">Importovať</button>
    </div>
  </div>

  <div class="offset-lg-1 col-lg-10 my-5 row">
    <div class="input-group  p-3 col-lg-6">
      <!-- <label class="input-group-text">Vyhladávanie: </label> -->
      <input type="text" class="form-control" placeholder="Hladať" [(ngModel)]="searchString">
      <label class="input-group-text"><i class="bi bi-search"></i></label>
    </div>
    <div class="p-3 col-lg-6">
      <select class="form-select" [(ngModel)]="classSelector">
        <option value="" >Všetky triedy</option>
        <option value="0" >Bez triedy</option>
        <option *ngFor='let class of classes'  [value]="class.name" >{{class.name}}</option>

      </select>
    </div>



    <table class="w-100 table table-hover">
      <thead>
        <tr class="row">
          <th scope="col" class="col-1" >
            <input *ngIf="users" style="margin-left: -5px;" type="checkbox" class="form-check-input" (click)="updateSelectedList(0, $event.target.checked)" name="" [checked]="allSelected()" value="">
          </th>
          <th class="col-4">Meno</th>
          <th class="col-4">Email/login</th>
          <th class="col-2">Rola</th>
          <th class="col-1">Trieda</th>
        </tr>
      </thead>
      <tbody *ngIf="users">
        <tr *ngFor="let user of tableFilter(); let i = index"  class=" row">
          <td class="col-1 ">
            <input type="checkbox" class="form-check-input" (click)="updateSelectedList(user.id)" name="" [checked]="inList(user.id)" value="">
          </td>
          <td class="col-4 clickable" href="#userEditorForm" (click)="loadUserEditor(user)">{{user.username}}</td>
          <td class="col-4 clickable" (click)="loadUserEditor(user)">{{user.email}}</td>
          <td class="col-2 clickable" (click)="loadUserEditor(user)">{{user.role}}</td>
          <td class="col-1 clickable" (click)="loadUserEditor(user)">
            <ng-container *ngIf="user.class">{{user.class}}</ng-container>
          </td>
        </tr>
        <tr *ngIf="tableFilter().length == 0"  class="row">
          <td class="col-12">Nenašli sa žiadny používatelia.</td>
        </tr>
      </tbody>
      <tbody  *ngIf="!users">
        <tr class="row">
          <td class="col-12">Načítavám... </td>
        </tr>
      </tbody>
    </table>

    <div class=" col-12 row" *ngIf="selectedUsers.length > 0">
      <button type="button"  class="btn btn-sm btn-secondary col-lg-4" name="button" (click)="deleteMany()">Odstrániť označené ({{selectedUsers.length}})</button>

      <div class="input-group  col-lg-8" style="justify-content: flex-end">
        <select class="form-select" [(ngModel)]="newClassSelector" style="max-width: 200px">
          <option value="" selected hidden disabled>Vyberte triedu...</option>
          <option value="0" >Bez triedy</option>
          <option *ngFor='let class of classes'  [value]="class.id" >{{class.name}}</option>

        </select>
        <button type="button" name="button" class="btn btn-sm btn-secondary" (click)="updateClasses()">Zmeniť triedu ({{selectedUsers.length}})</button>
      </div>

    </div>

    <div class="d-flex mt-3">
      <button  type="button" name="button" class="btn btn-secondary me-3 p-0" >
          <label for="file" style="margin: 0; color:white" class="clickable btn">Importovať študentov</label>
      </button>
      <input type="file" #takeInput  style="display: none" id="file"  accept=".csv" (change)="onFileChanged($event)">

      <button type="button" name="button" class="btn btn-primary " (click)="loadUserEditor()">Nový užívateľ</button>
    </div>
  </div>






</div>

<div class="form-group mb-3" *ngIf="!imgFile">
  <label class="customFileInput" id="customFileInput{{question_id}}" for="defaultFileInput{{question_id}}"></label>
  <input id="defaultFileInput{{question_id}}" type="file" class="form-control defaultFileInput"
         (change)="onImageChange($event)">
</div>

<div class="img-place" *ngIf="imgFile">
  <img class="mx-auto d-block image" style="width: 200px"
  src="{{imgFile}}">
  <div class="middle">
    <button class="btn btn-danger" type="button" (click)="onImageRemove()">Odstraniť</button>
  </div>
</div>

import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { SubjectsService } from '../../services/subjects.service';
import { TestService } from '../../services/test.service';
import { FormControl, FormGroup } from '@angular/forms';
import { GlobalService, UserService } from '@services/global.service';

import { FilterBySearchPipe } from '@root/pipes/filter-by-search.pipe';

@Component({
  selector: 'app-test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.css']
})
export class TestResultsComponent implements OnInit {

  subjectId;
  lessonId;
  testData: any = {};
  lesson;
  results;
  currentData;
  filterQuery;

  years = [];
  classes = [];
  toShowResults;

  pagination = {
    activePage: 0,
    count: 0,
    pages: []
  };

  pageSize = 10;

  classFilter;
  searchFilter;

  checkedList = [];
  masterChecked = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subjectsSrvc: SubjectsService,
    private testSrvc: TestService,
    private global: GlobalService,
    private userSrvc: UserService,
    private filterPipe: FilterBySearchPipe
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
        console.log(routeParams);

        this.lessonId = Number(routeParams.lessonId);
        this.subjectId = Number(routeParams.subjectId);

        this.testData.id = Number(routeParams.testId)
        this.testData.name = routeParams.testName

        if(!this.testData) {
          this.router.navigate(['/subjects',this.subjectId,this.lessonId]);
          return
        }


        this.loadResults();
      });

  }


  showResults(data){
    this.currentData = data;
  }


  updateChecked(e){

    let source = e.target.id;
    let activePage = this.pagination.pages[this.pagination.activePage];

    if(source == 'master'){
      if(e.target.checked){
        activePage.items.forEach(item => {
          if(!this.checkedList.some(x => item.id == x)){
            this.checkedList.push(item.id)
          }
        })
      }else{
        activePage.items.forEach(item => {
          const index = this.checkedList.indexOf(item.id);
          if (index !== -1) {
            this.checkedList.splice(index, 1);
          }
        })
      }
    }else{
      source = parseInt(source);
      if(e.target.checked){
        this.checkedList.push(source)
      }else{
        const index = this.checkedList.indexOf(source);
        if (index !== -1) {
          this.checkedList.splice(index, 1);
        }
      }
    }

    //asign to DOM
    this.results.forEach(res => {
      if(res.checked){
        if(!this.checkedList.includes(res.id)){
          res['checked'] = false;
        }

      }else{
        if(this.checkedList.includes(res.id)){
          res['checked'] = true;
        }
      }
    })


    this.updateResults()
  }


  loadResults(){
    this.testSrvc.getTestResults(this.testData.id)
      .subscribe(res => {
        if(res.status){
          let data = Object.values(res.data) as any;

          this.userSrvc.getAllUsers()
            .subscribe(usersRes =>{

              let userData = Object.values(usersRes.data);
              
              for (const element of data) {
                let currentUser = userData.find(x => x.id === element['user_id']);

                if(currentUser) {
                  element['name'] = currentUser.username;
                  element['class'] = currentUser.class;
  
                  if(!this.classes.includes(currentUser.class) && currentUser.class != ''){
                    this.classes.push(currentUser.class);
                  }
                } else {
                  element['name'] = 'Neznámi študent';
                  element['class'] = 'neznáma';
                }
                

                let tb_unf = new Date(element['time_begin']);
                element['time_begin_transformed'] = ("0"+tb_unf.getDate()).substr(-2) + "."
                + ("0"+(tb_unf.getMonth()+1)).substr(-2) + "."+ tb_unf.getFullYear() +" - " + tb_unf.getHours() +":" + ("0"+tb_unf.getMinutes()).substr(-2);


                element['time'] = (element['time_finish']-element['time_begin']);
                if(element['time'] < 0){
                    element['time'] = "nedokončený"
                }else{
                  element['time'] = this.global.formatTime(element['time']/1000)
                }
              }

            })

          data.forEach(result => {
            const year = new Date(result.time_begin).getFullYear()

            if(!this.years.includes(year)){
              this.years.push(year)
            }
          });

          this.results = data;

          this.toShowResults = this.results;

          this.updatePaginator();

        }else{
          console.log(res);
        }
      })
  }

  loadLesson(){
      //Načítanie dát k lekcií 'lesson'
      this.subjectsSrvc.getSingleLesson(this.lessonId)
        .subscribe(
          data => { //next - keď sa objavia dáta
            if(data.id){
              this.lesson = data
              this.loadResults();
            }else{
              this.router.navigate(['/subjects/'+this.subjectId+"/"+this.lessonId]);
            }

          })
  }

  updateResultsSize(e){
    this.pageSize = e.target.value;
    this.updateResults();
  }

  changeClass(e){
    this.classFilter = e.target.value;
    this.updateResults()
  }

  searchByName(e){
    this.searchFilter = e.target.value;
    this.updateResults()
  }

  yearFilter: string;
  changeYear(year: string) {
    this.yearFilter = year;
    this.updateResults()
  }

  updateResults(){

    this.toShowResults = this.results;


    if(this.classFilter){
      const class_select = this.classFilter;
      if(class_select == 'all'){
        this.toShowResults = this.toShowResults;
      }else {
      this.toShowResults = this.toShowResults.filter(result => result.class == class_select)
      }
    }

    if(this.yearFilter) {
      const year_select = this.yearFilter;
      if(year_select == 'all'){
        this.toShowResults = this.toShowResults;
      }else {
      this.toShowResults = this.toShowResults.filter(result => (new Date(result.time_begin).getFullYear() as unknown) as string == year_select)
      }
    }

    if(this.searchFilter){
      this.toShowResults = this.filterPipe.transform(this.toShowResults, this.searchFilter);

    }

    this.updatePaginator();

  }



  updatePaginator(){

    const last_active_page = this.pagination.activePage;



    this.pagination = {
      count: 0,
      pages: [],
      activePage: 0
    };


    const itemPerPage = this.pageSize;

    let pages_count = this.toShowResults.length/itemPerPage;
    if(pages_count > 0){
      if(pages_count % 1 !== 0){
        pages_count += 1;
      }
    }else{
      pages_count = 1;
    }

    this.pagination['count'] = Math.floor(pages_count);


    for(let page = 0; page < this.pagination.count; page++){
      let obj = {
        id: page,
        active: false,
        items: []
      };

      this.pagination.pages.push(obj);

      for (let i = 0; i < itemPerPage; i++){
        const pos = page*itemPerPage + i;
        if(pos < this.toShowResults.length) {
          this.pagination.pages[page].items.push(this.toShowResults[pos])
        }
      }
    }

    if(last_active_page < this.pagination.count){
      this.updatePage(last_active_page);
    }else{
      this.updatePage();
    }
  }

  updatePage(pageId=0){

    const activePage = this.pagination.pages.findIndex(x => x.active == true);

    if(activePage >= 0){
      this.pagination.pages[activePage].active = false;
    }

    this.toShowResults = this.pagination.pages[pageId].items;
    this.pagination.activePage = pageId;
    this.pagination.pages[pageId].active = true;

    let curr_page = this.pagination.pages[pageId];


    if(curr_page.items.filter(x => x.checked).length == curr_page.items.length){
      //force off master checked
      this.masterChecked = true;
    }else{
      this.masterChecked = false;
    }
  }


  sortParam: string;
  sortDirection: string;
  sortResults(param) {
    this.sortParam = param

    if(this.sortDirection == 'asc') {
      this.sortDirection = 'desc'
      this.results.sort((a, b) => {
        let valueA = parseInt(a[this.sortParam]) ? parseInt(a[this.sortParam]) : -1
        let valueB = parseInt(b[this.sortParam]) ? parseInt(b[this.sortParam]) : -1

        return valueB - valueA
      })

    } else {
      this.sortDirection = 'asc'
      this.results.sort((a, b) => {

        let valueA = parseInt(a[this.sortParam]) ? parseInt(a[this.sortParam]) : -1
        let valueB = parseInt(b[this.sortParam]) ? parseInt(b[this.sortParam]) : -1

        return valueA - valueB
      })

      console.log(this.results);

    }


    this.updateResults()
  }

}

<nav class="navbar navbar-light bg-light mb-3">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" *ngFor="let crumb of crumbs; let i = index" [class.active]="i+1 == crumbs.length" [attr.aria-current]="i+1 == crumbs.length ? 'page': null">
      <a *ngIf="i+1 != crumbs.length" [routerLink]="[crumb.route]">{{crumb.label}}</a>
      <span *ngIf="i+1 == crumbs.length">{{crumb.label}}</span>
    </li>
  </ol>
  <div class="lesson-search" *ngIf="!lessonId">
    <div class="input-group">
      <span class="input-group-text"><i class="bi bi-search form-control-feedback"></i></span>
      <input list="lessonData" type="text" name="lessonName" class="form-control" placeholder="Vyhľadať lekciu" (change)="selectLesson($event)" (click)="loadAllLesson()" autocomplete="off">
    </div>

    <datalist id="lessonData" *ngIf="allLessons">
      <option *ngFor="let lesson of allLessons" [value]="lesson.name">{{lesson.subject_name}}</option>
    </datalist>
  </div>
</nav>

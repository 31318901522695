import { Component, OnInit, OnChanges } from '@angular/core';
import { UserService } from './services/user.service';
import { map } from "rxjs/operators";
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private userSrvc: UserService,
    private auth: AuthService,
  ) { }

  title = 'proscholaris';

  ngOnInit(): void {




    // this.userSrvc.getUserData().pipe(
    //   map(res => {
    //     console.log(res);
    //
    //   })
    // )

  }




}

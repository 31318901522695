

<ng-container *ngIf="this.testData">
  <h3><a [routerLink]="['/subjects', subjectId, info.lessonId]">{{testData.name}}</a>  Test</h3>

   <app-result-detail *ngIf="results && showResults" [data]="results"></app-result-detail>



   <div *ngIf="!testRun && !testFinish && !testReview" class="intro">
     <div class="card" *ngIf="!testReady">
      <div class="card-body">
        <h5 class="card-title">Tento test žiaľ nie je dostupný.</h5>
        <p class="card-text" *ngIf="clientMessage.timeRestriction.time">Bude dostupný od <b>{{clientMessage.timeRestriction.time}}</b></p>
        <p class="card-text" *ngIf="clientMessage.roundsRestriction.rounds">Test ste spustili maximálny počet krát! ({{clientMessage.roundsRestriction.rounds}}x)</p>
      </div>
    </div>

     <div class="card" *ngIf="testReady">
      <div class="card-body">
        <h5 class="card-title">Tento test už na Teba čaká. Keď budeš pripravený, neváhaj ho spustiť.</h5>
        <p class="card-text" *ngIf="timeLimit">Na vypracovanie máš presne<b> {{timeLimit}} minút</b></p>
        <button  class="btn btn-success" (click)="runTest()">Spustiť test</button>
      </div>
    </div>

   </div>


    <div *ngIf="timeLimit && testRun" class="timer sticky-top pt-5 mt-3">
      <h4>{{timeLimit}}</h4>
    </div>



  <div class="test container" *ngIf="testRun">
    <h6>{{testData.description}}</h6>
    <ng-container *ngIf="testData.questions">
      <div class="question" *ngFor="let question of testData.questions">
        <h5>{{question.description}}</h5>

        <img class="mx-auto d-block" *ngIf="question.material_source" src="https://nastuduj.sk/storage/test_files/{{question.material_source}}">
        <app-question-mark-correct *ngIf="question.type == 'markCorrect'" [question]="question" (updateEmit)="updateItem($event, question.id)"></app-question-mark-correct>
        <app-question-fill-in *ngIf="question.type == 'fillIn'" [question]="question" (updateEmit)="updateItem($event, question.id)"></app-question-fill-in>
        <app-question-find-pair *ngIf="question.type == 'findPair'" [question]="question"  (updateEmit)="updateItem($event, question.id)"></app-question-find-pair>
        <app-question-find-order *ngIf="question.type == 'findOrder'" [question]="question"  (updateEmit)="updateItem($event, question.id)"></app-question-find-order>
        <hr>
      </div>
      <button class="btn btn-primary" type="button" *ngIf="testRun" (click)="finishTest('Paráda test sa ti podarilo dokončiť včas!')">Vyhodnotiť</button>
    </ng-container>

    <div *ngIf="!testData.questions" class="alert alert-danger" role="alert">
      Ale nie! tento test je pokazený :(. Prosím dajte nám o tom vedieť.
      Tím vysoko trénovaných opíc bude vyslaní aby vyriešil tento problém! <br>
       <b> KÓD CHYBY: {{testData.id}} </b>
    </div>
  </div>

  <div class="card" *ngIf="testFinish">
   <div class="card-body">
     <h5 class="card-title">Hotovo! {{finishText}}</h5>
     <p class="card-text" *ngIf="showResults">Ak chceš vedieť, ako sa ti darilo, pozri si hodotenie svojho testu.</p>
     <p class="card-text" *ngIf="!showResults">Výsledky budú k dispozícií neskôr.</p>
     <button  class="btn btn-secondary" [routerLink]="['/subjects', subjectId, info.lessonId]">Spať k lekcií</button>
     <button  class="btn btn-primary ms-3" *ngIf="showResults"  data-bs-toggle="modal" data-bs-target="#resultsDetail" (click)="review()">Zobraziť výsledky</button>
   </div>
  </div>

</ng-container>

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CustomEvaluators } from './custom-evaluators';
import { ITest, IAnswer } from '../interfaces/test';
import { ApiService } from '@services/api.service'
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})


export class TestService {
  private apiUrl= this.api.url

  public results = {};
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    }),
    params: {}
  };


  constructor(
    private http: HttpClient,
    private evaluator: CustomEvaluators,
    private api: ApiService,
    private user: UserService
  ) {
  }


  getTest(id){
    return this.http.get<ITest>(this.apiUrl+'/lessons/test/read_single.php?id='+id);
  }

  getTestAnswers(testId){
    return this.http.get<IAnswer[]>(this.apiUrl+'/lessons/test/read_answers.php?test_id='+testId);
  }

  updateTest(values){
    return this.http.post<{message: string;}>(this.apiUrl+'/lessons/test/update.php', values, this.httpOptions);
  }

  createTest(values) {
    return this.http.post(this.apiUrl+'/lessons/test/create.php', values, this.httpOptions);
  }

  evaluate(testData, answersData): any{
    //Vyhodnotenie testu
    //Vrati Observable s vysledkami

    const questions = testData.questions;
    let results;

    return this.getTestAnswers(testData.id)
      .pipe(map(testAnswers => {
          //Answers obtained

          let returnObj = {};
          let fetchedAnswers = {};
          let score = 0;
          questions.forEach(question => {
              switch (question.type) {
                case "markCorrect":
                  fetchedAnswers[question.id] = this.evaluator.evolveMarkCorrect(question, testAnswers, answersData);
                  break;
                case "fillIn":
                  fetchedAnswers[question.id] = this.evaluator.evolveFillIn(question, testAnswers, answersData);
                  break;
                case "findPair":
                  fetchedAnswers[question.id] = this.evaluator.evolveFindPair(question, testAnswers, answersData);
                  break;
                case "findOrder":
                  fetchedAnswers[question.id] = this.evaluator.evolveFindOrder(question, testAnswers, answersData);
                  break;
                }

                score += fetchedAnswers[question.id].percent;
          });

          score = score/Object.values(fetchedAnswers).length;

          let end_score = Math.round((score + Number.EPSILON) * 100) / 100;

          returnObj = {answers: fetchedAnswers, score: end_score};
          return returnObj;
        }

      ));
  }


  getTestStatus(test: any): string {
    let status = 'dostupný'
    const sett = test.settings


    if(this.user.isTeacher()) {
      // return status
    }

    if(sett.startTime) {
      if(new Date(sett.startTime) > new Date()) {
        status = "dostupný od " + new Date(sett.startTime).toLocaleString()
      }
    }

    if(sett.endTime) {
      if(new Date(sett.endTime) < new Date()) {
        status = "ukončený"
      }
    }



    return status;
  }


  sendResult(values){
    return this.http.post(this.apiUrl+'/lessons/test/results/upload.php', values, this.httpOptions);
  }

  getTestResults(test_id){
    return this.http.get<{status: number, data: {}}>(this.apiUrl+'/lessons/test/results/read_test.php?test_id='+test_id);
  }

  getUserTests(test_id, user_id){
    return this.http.get<{status: number, data: {}, message?: any}>(this.apiUrl+'/lessons/test/results/read_user.php?test_id='+test_id+'&user_id='+user_id);
  }

  //Vymazat predmet
  deleteTest(testId){
    return this.http.delete(this.apiUrl+"/lessons/test/delete.php?id="+testId)
  }

}

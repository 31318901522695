import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { SubjectsService } from '../../services/subjects.service';
import { TestService } from '../../services/test.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-test-editor',
  templateUrl: './test-editor.component.html',
  styleUrls: ['./test-editor.component.css']
})
export class TestEditorComponent implements OnInit {
  lesson: any = {}
  subjectId;
  testData: any = {}
  testAnswers;

  testDescription = new UntypedFormControl();
  testName = new UntypedFormControl('', Validators.required);

    testSettingsForm = new UntypedFormGroup({
    showResults: new UntypedFormControl(true),
    limitTime: new UntypedFormControl(false),
    timeLimit: new UntypedFormControl(),
    startTime: new UntypedFormControl(),
    endTime: new UntypedFormControl(),
    random: new UntypedFormControl(false),
    randomCount: new UntypedFormControl(),
    limitRounds: new UntypedFormControl(false),
    roundsLimit: new UntypedFormControl()
  });


  questions = [];
  answers = [];
  questions_index = 1;
  fileToUpload: File = null;
  questionType = ""

  questions_list = {
    "Výber možností":{
      "id": 0,
      "type": "markCorrect",
      "material_source": "",
      "description": "",
      "options": []
    },
    "Doplňovacie otázky":{
      "id": 0,
      "type": "fillIn",
      "material_source": ""
    },
    "Nájsť pár":{
      "id": 0,
      "type": "findPair",
      "material_source": "",
      "options": {
        "left_side": [],
        "right_side": []
      }
    },
    "Zoradiť":{
      "id": 0,
      "type": "findOrder",
      "material_source": "",
      "options": []
    }
  };

  answers_list = {
    "Výber možností": {
      "questionId": 0,
      "answers": []
    },
    "Doplňovacie otázky":{
      "questionId": 0,
      "answers": []
    },
    "Nájsť pár":{
      "questionId": 0,
      "answers": []
    },
    "Zoradiť":{
      "questionId": 0,
      "answers": []
    }
  }


  constructor(
      private router: Router,
      private route: ActivatedRoute,
      private subjectsSrvc: SubjectsService,
      private testSrvc: TestService
  ) { }


  ngOnInit(): void {
    this.route.params.subscribe( routeParams => {
        this.lesson.id = Number(routeParams.lessonId);
        this.subjectId = Number(routeParams.subjectId);


        this.loadLesson(this.subjectId, this.lesson.id)

        let testId = routeParams.testId
        if(testId) {
          // uprava testu
          this.testData.id = testId
          this.loadTest(testId)
        } else {
          //novy test
        }
    });


    this.testSettingsForm.controls.limitTime.valueChanges.subscribe(val => {
        if(val == false){
          this.testSettingsForm.controls.timeLimit.disable();
        }else {
          this.testSettingsForm.controls.timeLimit.enable();
          this.testSettingsForm.controls.timeLimit.setValidators([Validators.required, Validators.min(1)]);
        }
    })

    this.testSettingsForm.controls.limitRounds.valueChanges.subscribe(val => {
        if(val == false){
          this.testSettingsForm.controls.roundsLimit.disable();
        }else {
          this.testSettingsForm.controls.roundsLimit.enable();
          this.testSettingsForm.controls.roundsLimit.setValidators([Validators.required, Validators.min(1)]);
        }
    })

    this.testSettingsForm.controls.random.valueChanges.subscribe(val => {
      if(val == false){
        this.testSettingsForm.controls.randomCount.disable();
      }else {
        this.testSettingsForm.controls.randomCount.enable();
        this.testSettingsForm.controls.randomCount.setValidators([Validators.required, Validators.min(1), Validators.max(this.questions.length)]);
      }

    })


    this.testSettingsForm.controls.limitTime.patchValue(false)
    this.testSettingsForm.controls.random.patchValue(false)
    this.testSettingsForm.controls.limitRounds.patchValue(false)

  }


  loadLesson(subjectId, lessonId){
      //Načítanie dát k lekcií 'lesson'
      this.subjectsSrvc.getSingleLesson(lessonId)
        .subscribe(
          data => { //next - keď sa objavia dáta
            this.lesson = data
            if(!this.lesson.error) {
              if(this.lesson.subjectId != subjectId ){
                //Vráti užívatela na správnu adresu - rozhoduje ID lekcie
                // this.router.navigate(['/subjects/',this.lesson.subjectId+"/"+this.lesson.id]);
                console.log(data.subjectId);
              }
            }else {
              this.router.navigate(['/subjects', this.subjectId, lessonId])
            }

          },
          error => {console.log(error)},
        );
    }

  loadTest(testId) {
    this.testSrvc.getTest(testId)
      .subscribe(data => {
        if(data.error){
          console.error(data.error)
          alert(data.error)
          this.router.navigate(['/subjects', this.subjectId, this.lesson.id])

          return
        }

        
        this.testData = data;

        if(!this.testData.questions) {
          this.testData.questions = []
        }
        
        this.questions = this.testData.questions;
        

        if(this.questions.length > 0){
          this.questions_index = parseInt(this.questions[this.questions.length-1].id)+1;
        }

        let settings = this.testData.settings;
        // console.log(settings);


        this.testSettingsForm.patchValue({
          showResults: settings.showResults,
          limitTime: settings.limitTime,
          timeLimit: settings.timeLimit,
          startTime: settings.startTime,
          endTime: settings.endTime,
          random: settings.random,
          randomCount: settings.randomCount,
          limitRounds: settings.limitRounds,
          roundsLimit: settings.roundsLimit
        })
        this.testName.patchValue(this.testData.name)
        this.testDescription.patchValue(this.testData.description)

        if(!settings.hasOwnProperty("limitTime")){
          this.testSettingsForm.controls.timeLimit.disable();
        }

        if(!settings.hasOwnProperty("random")){
          this.testSettingsForm.controls.randomCount.disable();
        }
        if(!settings.hasOwnProperty("limitRounds")){
          this.testSettingsForm.controls.limitRounds.disable();
        }
        this.testSrvc.getTestAnswers(testId)
          .subscribe(data => {
            this.testAnswers = data;
            this.answers = this.testAnswers
          });

      });


  }

  onQuestionAdd(questionType){
    if(questionType){
      const new_question = this.questions_list[questionType];
      new_question.id = this.questions_index;
      this.questions.push(JSON.parse(JSON.stringify(new_question)));

      const new_answer = this.answers_list[questionType];
      new_answer.questionId = this.questions_index;
      this.answers.push(JSON.parse(JSON.stringify(new_answer)));

      this.questions_index++;
      this.questionType = ""
    }
  }

  deleteQuestion(index) {
    this.questions.splice(index, 1);
    this.answers.splice(index, 1);
  }

  onQuestionUpdate(e, questionId){
    this.questions.find( element => {
      if(element.id == questionId){
        element = e;
      }
    });

    // console.log("Otazky: " , this.questions);

  }

  onAnswersUpdate(e, questionId){
    this.answers.find( element => {
      if(element.questionId == questionId){
        element.answers = e;
      }
    });
    // console.log("Odpovede: " , this.answers);
  }

  onTestSubmit() {
    if(this.testData.id){
      this.updateTest()
    }
    else{
      this.createTest()
    }
  }

  createTest() {

    const data = {
      "questions": this.questions,
      "answers": this.answers,
      "name": this.testName.value,
      "description": this.testDescription.value,
      "lessonId": this.lesson.id,
      "settings": this.testSettingsForm.value
    }

    this.testSrvc.createTest(data)
      .subscribe((res: any) => {
        console.log(res);
        this.router.navigate(['/subjects', this.subjectId, this.lesson.id])
      })

  }

  updateTest() {
    this.testData.description = this.testDescription.value;
    this.testData.name = this.testName.value;
    this.testData.answers = this.answers;
    this.testData.settings = this.testSettingsForm.value;
    console.log(this.testData);

    this.testSrvc.updateTest(JSON.stringify(this.testData))
      .subscribe(data => {
        alert(data.message);
        this.router.navigate(['/subjects', this.subjectId, this.lesson.id])
      })
  }

  deleteTest() {
    if(confirm("Určite chcete odstrániť tento test?")) {
      this.testSrvc.deleteTest(this.testData.id).subscribe(res => {
        console.log(res);

        this.router.navigate(['/subjects', this.subjectId, this.lesson.id])
      })
    }
  }


}

<app-top-bar></app-top-bar>
<div class="container-fluid">
  <!-- <div class="row"> -->
    <!-- <app-side-bar></app-side-bar> -->
  <!-- </div> -->
  <main class="ms-sm-auto px-lg-4 pb-5 mb-4">
    <router-outlet></router-outlet>
  </main>

</div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './services/token.interceptor';
import {YouTubePlayerModule} from '@angular/youtube-player';
import { Papa } from 'ngx-papaparse';


import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';

import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SideBarComponent } from './side-bar/side-bar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { LessonsComponent, FilterLessonsPipe } from './lessons/lessons.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { LessonDetailComponent } from './lesson-detail/lesson-detail.component';
import { TestComponent } from './lesson-detail/test/test.component';
import { QuestionMarkCorrectComponent } from './lesson-detail/test/question-mark-correct/question-mark-correct.component';
import { ResultDetailComponent } from './lesson-detail/test-results/result-detail/result-detail.component';
import { QuestionFillInComponent } from './lesson-detail/test/question-fill-in/question-fill-in.component';
import { FillInInputDirective } from './lesson-detail/test/question-fill-in/fill-in-input.directive';
import { QuestionFindPairComponent } from './lesson-detail/test/question-find-pair/question-find-pair.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DemoMaterialModule} from './material-module';
import { QuestionFindOrderComponent } from './lesson-detail/test/question-find-order/question-find-order.component';
import { TestEditorComponent } from './lesson-detail/test-editor/test-editor.component';
import { EditMarkCorrectComponent } from './lesson-detail/test-editor/edit-mark-correct/edit-mark-correct.component';
import { EditFillInComponent } from './lesson-detail/test-editor/edit-fill-in/edit-fill-in.component';
import { EditFindPairComponent } from './lesson-detail/test-editor/edit-find-pair/edit-find-pair.component';
import { EditFindOrderComponent } from './lesson-detail/test-editor/edit-find-order/edit-find-order.component';
import { QuestionTextPipe } from './pipes/question-text.pipe';
import { ImageUploadComponent } from './lesson-detail/test-editor/image-upload/image-upload.component';
import { LessonMaterialsComponent } from './lesson-detail/lesson-materials/lesson-materials.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { LoginComponent } from './user-forms/login/login.component';
import { PermissionsDirective } from './services/directives/permissions.directive';
import { FilterBySearchPipe } from './pipes/filter-by-search.pipe';
import { LimitSearchPipe } from './pipes/limit-search.pipe';
import { DndUploadDirective } from './services/directives/dnd-upload.directive';
import { UsersComponent } from './admin-panel/users/users.component';
import { UserDetailComponent } from './admin-panel/users/user-detail/user-detail.component';
import { VideoPlayerComponent } from './lesson-detail/lesson-materials/video-player/video-player.component';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { RegistrationComponent } from './user-forms/registration/registration.component';
import { TestResultsComponent } from './lesson-detail/test-results/test-results.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { ClassesComponent } from './admin-panel/classes/classes.component';


@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    SideBarComponent,
    DashboardComponent,
    PreferencesComponent,
    LessonsComponent,
    SubjectsComponent,
    LessonDetailComponent,
    TestComponent,
    QuestionMarkCorrectComponent,
    ResultDetailComponent,
    QuestionFillInComponent,
    FillInInputDirective,
    QuestionFindPairComponent,
    QuestionFindOrderComponent,
    TestEditorComponent,
    EditMarkCorrectComponent,
    EditFillInComponent,
    EditFindPairComponent,
    EditFindOrderComponent,
    QuestionTextPipe,
    ImageUploadComponent,
    LessonMaterialsComponent,
    FileManagerComponent,
    LoginComponent,
    PermissionsDirective,
    FilterBySearchPipe,
    LimitSearchPipe,
    DndUploadDirective,
    UsersComponent,
    UserDetailComponent,
    FilterLessonsPipe,
    VideoPlayerComponent,
    FirstLetterPipe,
    RegistrationComponent,
    TestResultsComponent,
    BreadcrumbComponent,
    ClassesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    YouTubePlayerModule,
  ],
  providers: [
    FilterBySearchPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

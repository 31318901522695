import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PermissionsDirective } from '../services/global.service';


import { SubjectsService } from '../services/subjects.service';

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.css'],

})
export class SubjectsComponent implements OnInit {
  public subjects = [];
  rows = [];

  subjectForm = this.formBuilder.group({
      name: ''
  });


  constructor(
    private subjectsSrvc: SubjectsService,
    private formBuilder: UntypedFormBuilder

  ) { }

  ngOnInit(): void {

    this.loadSubjects();


  }

  alphabet = []
  currentLetter = 'A'
  loadSubjects() {
    let colors = ['#D9B5B5', '#D9C6B5', '#D5D9B5', '#b5d9c8', '#b5c1d9'];

    this.subjectsSrvc.getSubjects()
      .subscribe(data => {
        this.subjects = data.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

        let i = 0;
        // this.subjects

        Object.keys(this.subjects).forEach(obj => {

          const firstLetter = this.subjects[obj].name.charAt(0).normalize("NFD").replace(/\p{Diacritic}/gu, "")
          if(!this.alphabet.includes(firstLetter)) this.alphabet.push(firstLetter)


          this.subjects[obj].color = colors[i];
          i++;
          if(i == colors.length){ i = 0}
        })

        this.filterSubjects('A')
      });



  }

  hashCode(str) { // java String#hashCode
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
       hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i){
      var c = (i & 0x00FFFFFF)
          .toString(16)
          .toUpperCase();

      return "00000".substring(0, 6 - c.length) + c;
  }

  onSubmit(): void {
    //Vytvorit novy predmet

    if(this.subjectForm.value.name.length < 3){
      return;
    }

    this.subjectsSrvc.createSubject(this.subjectForm.value)
      .subscribe(data => {
        console.log(data);
        this.loadSubjects()
      });

    this.subjectForm.reset();
  }

  onDelete(subjectId) {
    this.subjectsSrvc.deleteSubject(subjectId)
      .subscribe(data => {
        console.log(data);

        this.subjectsSrvc.getLessons(subjectId)
          .subscribe(data => {

            data.forEach(lesson => {
                this.subjectsSrvc.deleteLesson(lesson.id)
                  .subscribe(data => {
                    console.log(data);
                  });
            });
          })


        this.loadSubjects();
      });
  }

  visibleSubjects = []
  filterSubjects(letter: string) {
    this.currentLetter = letter

    this.visibleSubjects = this.subjects.filter(s => {
      if(s.name.charAt(0).normalize("NFD").replace(/\p{Diacritic}/gu, "") == this.currentLetter) return true

      return false
    })
  }

}

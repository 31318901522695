import { Component, OnInit } from '@angular/core';

import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '@services/user.service';

import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  constructor(
    private userSrvc: UserService,
    private snackBar: MatSnackBar
  ) { }

  response;

  registrationForm = new UntypedFormGroup({
    first_name: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
    last_name: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
    email: new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.email]),
    secret: new UntypedFormControl(''),
    password: new UntypedFormControl('', [Validators.required, Validators.minLength(5)]),
    passwordCheck: new UntypedFormControl('')

  })

  ngOnInit(): void {
    this.registrationForm.valueChanges.subscribe(x => {
      if(x.passwordCheck == x.password){
        this.registrationForm.controls['passwordCheck'].setErrors(null);
        // this.registrationForm.controls['passwordCheck'].;

      }else {
        this.registrationForm.controls['passwordCheck'].setErrors({'incorrect': true});
      }
    })

  }


  registerUser(){
      this.userSrvc.registerUser(this.registrationForm.value)
        .subscribe(res => {
            console.log(res);
            this.response = '';
            this.response = res;
            if(res.status == 200){
              this.response.message += " Prosím pokračujte na <a href='/login'>prihlásenie</a>. "
            }
        })
  }



}

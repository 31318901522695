import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SubjectsService } from "@services/subjects.service";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})



export class BreadcrumbComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private subjects: SubjectsService
  ) { }

  crumbs = [
    {label: 'Domov', route: '/'},
    {label: 'Predmety', route: '/subjects'}
  ]

  lessonId;
  allLessons;

  ngOnInit(): void {
    const routeData = this.route.snapshot.data;

    const subjectId = this.route.snapshot.params.subjectId;
    this.lessonId = this.route.snapshot.params.lessonId;

    if(routeData.subjectName){
        this.crumbs.push({label: routeData.subjectName, route: "/subjects/"+subjectId} )
    }

    if(routeData.lessonName){
        this.crumbs.push({label: routeData.lessonName, route: "/subjects/"+subjectId+"/"+this.lessonId} )
    }




  }

  selectLesson(e){
    const selected = this.allLessons.find(x => x.name == e.target.value);
    console.log(selected);

    this.router.navigateByUrl('subjects/'+selected.subject_id + "/" + selected.id);

  }

  loadAllLesson(){
    if(!this.allLessons){
      this.subjects.getLessons()
        .subscribe(res => {
          this.allLessons = res;

        })
    }

  }

}

import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

import { GlobalService } from '../../../services/global.service';

@Component({
  selector: 'app-question-find-order',
  templateUrl: './question-find-order.component.html',
  styleUrls: ['./question-find-order.component.css']
})
export class QuestionFindOrderComponent implements OnInit, OnChanges {
  @Input() question;
  @Input() results;
  @Output() updateEmit = new EventEmitter<any>();

  orderList = [];

  constructor(private globalSrvc: GlobalService) { }

  ngOnInit(): void {

    

    if(this.results) {
      console.log("RESULTS");
      this.orderList = this.question.options
    } else {
      this.orderList = this.globalSrvc.shuffle(this.question.options);
    }

    this.updateEmit.emit(this.orderList);

    if(this.results && this.orderList){
      this.updateChanges();
    }
  }

  ngOnChanges() {
    if(this.results && this.orderList){
      this.updateChanges();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    const item_new = this.orderList[event.previousIndex];
    const item_old = this.orderList[event.currentIndex];

    this.orderList[event.currentIndex] = item_new;
    this.orderList[event.previousIndex] = item_old;


    this.updateEmit.emit(this.orderList);
  }

  updateChanges(){
    const questionResults =  this.results[this.question.id];

    let i = 0;
    this.orderList.forEach(element => {
        if(questionResults.correct.some(e => e == element.order)){
          this.orderList[i]["correct"] = "true";
          if(this.orderList[i].incorrect){
            delete this.orderList[i].incorrect;
          }
        }else{
          this.orderList[i]["incorrect"] = "true";
          if(this.orderList[i].correct){
            delete this.orderList[i].correct;
          }
        }
        i++;
    });
  }




}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { ApiService } from '@services/api.service'

import { ISubject } from '../interfaces/subject';
import { ILesson } from '../interfaces/lesson';

@Injectable({
  providedIn: 'root'
})


export class SubjectsService {
  private apiUrl= this.api.url

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    }),
    params: {}
  };

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private api: ApiService
  ) { }

  //Subjects
  ///////////////////////////

  //vrati vsetky subjects
  getSubjects(){
    return this.http.get<ISubject[]>(this.apiUrl+"/subjects/read.php");
  }

  //Vybrat iba jeden subject
  getSingleSubject(subjectId){

    return this.http.get<ISubject>(this.apiUrl+'/subjects/read_single.php?id='+subjectId);
  }

  //vytvorit novy
  createSubject(values){
    return this.http.post<ISubject[]>(this.apiUrl+"/subjects/create.php", values, this.httpOptions)
  }

  updateSubject(values) {
    return this.http.post(this.apiUrl+"/subjects/update.php", values, this.httpOptions)
  }

  //Vymazat predmet
  deleteSubject(subjectId){
    let httpParams = new HttpParams().set('id', subjectId);
    this.httpOptions["params"] = httpParams;
    return this.http.delete<ISubject[]>(this.apiUrl+"/subjects/delete.php", this.httpOptions)
  }



  //Lessons
  ///////////////////////////
  getSingleLesson(lessonId){

    return this.http.get<ILesson>(this.apiUrl + '/lessons/read_single.php?id='+lessonId);
  }

  getLessons(subjectId = undefined){

    if(subjectId){
      return this.http.get<ILesson[]>
      (this.apiUrl+'/lessons/read_subject.php?subjectId='+subjectId);
    }else{
      return this.http.get<ILesson[]>
      (this.apiUrl+'/lessons/read.php');
    }
  }

  //vytvorit novu lekciu
  createLesson(values){
    return this.http.post<any>(this.apiUrl+"/lessons/create.php", values, this.httpOptions)
  }

  //Vymazat lekciu
  deleteLesson(lessonId){
    return this.http.delete<ILesson[]>(this.apiUrl+"/lessons/delete.php?id="+lessonId, this.httpOptions)
  }

  updateLesson(values) {
    return this.http.post(this.apiUrl+"/lessons/update.php", values, this.httpOptions)
  }

  //Classes
  ///////////////////////////
  getClasses(){
    return this.http.get<any>(this.apiUrl+"/classes/read.php");
  }

  createClass(values){
    return this.http.post<any>(this.apiUrl+"/classes/create.php", values, this.httpOptions)
  }

  updateClass(values) {
    return this.http.post<any>(this.apiUrl+"/classes/update.php", values, this.httpOptions)
  }

  deleteClass(classId){
    return this.http.get<any>(this.apiUrl+"/classes/delete.php?id="+classId, this.httpOptions)
  }

  // Lessons Groups

  getLessonsGroups(subjectId: string) {
    return this.http.get<ILesson>(this.apiUrl + '/lessons/groups/read_subject.php?subjectId='+subjectId, this.httpOptions);
  }

  createLessonGroup(values){
    return this.http.post<any>(this.apiUrl+"/lessons/groups/create.php", values, this.httpOptions)
  }

  updateLessonGroup(values) {
    return this.http.post(this.apiUrl+"/lessons/groups/update.php", values, this.httpOptions)
  }

  deleteLessonGroup(groupId){
    console.log(groupId);

    return this.http.get<any>(this.apiUrl+"/lessons/groups/delete.php?id="+groupId, this.httpOptions)
  }



}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {


  subPages: {name: string, label: string, active: boolean, children?: any[]}[] = [
    // {name: "Prehľad", label: 'dashboard', active: false},
    {name: "Domov", label: '/', active: false},
    {name: "Predmety", label: 'subjects', active: false},
    {name: "Nastavenia", label: 'preferences', active: false},

  ]

  constructor(
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.auth.userEmitter.subscribe(res => {


      if(res.data) {
        if(res.data.role == 'admin') {
          this.subPages = [
            {name: "Domov", label: '/', active: true},
            {name: "Predmety", label: 'subjects', active: false},
            {name: "Nastavenia", label: 'preferences', active: false},
            {name: "Správa systému", label: 'users', active: false,
              children: [
                {name: "Používatelia", label: 'users', active: false},
                {name: "Triedy", label: 'classes', active: false}
              ]
            },
          ]
          return
        }

      } else {
        this.subPages = []
        return
      }

      this.subPages = [
        {name: "Domov", label: '/', active: false},
        {name: "Predmety", label: 'subjects', active: false},
        {name: "Nastavenia", label: 'preferences', active: false},
      ]

    })
  }


}

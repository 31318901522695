import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, FormControl } from '@angular/forms';

import { SubjectsService } from '../../services/subjects.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.css']
})
export class ClassesComponent implements OnInit {

  classes;

  classForm;


  constructor(
    private subjectsSrvc: SubjectsService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.loadClasses()
  }

  loadClasses() {
    this.subjectsSrvc.getClasses().subscribe(
      classes => this.classes = classes
    )
  }

  loadClassForm(data: any = null) {

    this.classForm = this.fb.group({
      id: '',
      name: ''
    })

    if(data) {
      this.classForm.patchValue(data)
    }

  }

  updateClass() {
    this.subjectsSrvc.updateClass(this.classForm.value).subscribe(
      res => {
        alert(res.message)
        this.classForm = null;
        this.loadClasses()
      }
    )
  }

  createClass() {
    this.subjectsSrvc.createClass(this.classForm.value).subscribe(
      res => {
        alert(res.message)
        this.classForm = null;
        this.loadClasses()
      }
    )
  }

  deleteClass(classData: any) {
    if(!confirm("Určite chcete zmazať triedu '"+classData.name+"'?")) return
    this.subjectsSrvc.deleteClass(classData.id).subscribe(
      res => {
        alert(res.message);
        this.classForm = null;
        this.loadClasses()
      }
    )
  }

}

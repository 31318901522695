<div class="modal" tabindex="-1" id="videoPlayer">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{video.name}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
        <div class="modal-body mx-auto">
          <youtube-player #player (ready)="onReady($event)" [videoId]="videoId" ></youtube-player>
      </div>
    </div>
  </div>
</div>


<!-- <iframe width="100%" height="100%" src="https://www.youtube.com/embed/TMdOHI_tiDw" [src]='safeURL'
title="YouTube video player" frameborder="0"
allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->



<div class="modal fade" id="fileManager" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Knižnica súborov</h5>
        <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close" (click)="onClose.emit()"></button>
      </div>

      <div class="modal-body">
        <div class="container">
          <nav class="navbar navbar-light bg-light">
            <div class="container-fluid">
              <form class="d-flex">
                <input class="form-control me-2" type="search" placeholder="Vyhľadávanie" name="search" [(ngModel)]="filterQuery" >
                <button class="btn btn-outline-success" type="submit">Hladať</button>
              </form>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" name="subjectOnly"[(ngModel)]="showSubjectOnly">
                <label class="form-check-label" for="inlineCheckbox1" >Zobraziť iba materiály k predmetu</label>
              </div>

            </div>
          </nav>

          <!-- Scrollable module -->
          <cdk-virtual-scroll-viewport itemSize='10'  class="example-viewport w-100 p-5 my-5 position-relative">
            <div appDndUpload (status)="uploading = $event">

              <div class="uploading-view"  *ngIf="uploading">

                <div class="file-form " *ngIf="hasFile">

                  <div class="card mx-auto mt-5 text-center w-75">

                    <div class="card-body">
                      <h5 class="card-title">{{newFile.name}}</h5>
                      <p class="card-text">Želáte si nahrať tento súbor?</p>
                      <button class="btn btn-danger mx-3">Nie</button>
                      <button class="btn btn-success mx-3" (click)="uploadFile()">Áno</button>
                    </div>
                  </div>

                </div>


                <div class="dropzone mx-auto" *ngIf="!hasFile">
                  <input type="file" class="fileInput" #fileDropRef multiple (change)="fileBrowseHandler($event)"/>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                  </svg>
                  <h3>Nahrajte súbor potiahnutím sem</h3>
                  <h5>alebo</h5>
                  <div class="d-grid gap-2 col-3 mx-auto">
                    <label id="materialUploadLabel" for="materialUploadInput" class="btn btn-primary">vyberte tu..</label>
                    <input type="file" class="fileInput" id="materialUploadInput"  (change)="fileBrowseHandler($event)"/>
                  </div>
                  <h5>alebo vložte odkaz na video</h5>
                  <div class="form-group d-grid gap-2 col-6 mx-auto">
                    <div class="input-group">
                      <input type="text" class="form-control" placeholder="https://www.youtube.com/watch" [formControl]="videoUrl" minlength="10" maxlength="70" required>
                      <button type="button" class="btn btn-success " [disabled]="!videoUrl.valid" (click)="uploadVideo()"><i class="bi-check-lg"></i></button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="wrapper" *ngIf="!uploading">

                <div style="margin-left: 0; margin-right: 0;" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-2 gy-5" *ngIf="materials.length > 0">

                  <div class="col" *ngFor="let material of materials | filterBySearch:filterQuery | limitSearch:showSubjectOnly:subjectId">
                    <div class="card material-card"
                    (click)="markMaterial(material.id)" [ngClass]="{'marked' : marked == material.id}" (dblclick)="addToLesson()">
                      <img [src]="material.thumbnail" class="card-img-top" width="100%" height="100%" alt="...">
                      <div class="card-body">
                        <h6 class="card-title">{{material.name |  uppercase}}</h6>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


            </div>
          </cdk-virtual-scroll-viewport>
        </div>
      </div>


      <div class="modal-footer">
        <div class="d-grid gap-2 col-4 mx-auto" *ngIf="!addFormSwitch">
          <button *ngIf="!uploading" (click)="uploading = true" class="btn btn-success">Nahrať materiál <i class="bi bi-upload"></i></button>
          <button *ngIf="uploading" (click)="uploading = false" class="btn btn-warning" style="color: white;">Späť k materiálom</button>
        </div>

        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="onClose.emit(); marked = 0">Zavrieť</button>
        <button type="button" class="btn btn-danger" [disabled]="marked == 0">Odstrániť</button>
        <button type="button" class="btn btn-primary" (click)="addToLesson()" [disabled]="marked == 0" data-bs-dismiss="modal" >Pridať k lekcií</button>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { PermissionsDirective } from "./directives/permissions.directive";
import { AuthService } from './auth.service';
import { UserService } from './user.service';




@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }


  formatTime(timeInSec){
    let mins = Math.floor(timeInSec / 60);
    let secs = Math.floor(timeInSec % 60);

    return this.str_pad_left(mins,'0',2) + ":" + this.str_pad_left(secs,'0',2);
  }

  str_pad_left(string,pad,length) {
      return (new Array(length+1).join(pad)+string).slice(-length);
  }

  shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  genCharArray(charA, charZ) {
    let a = [], i = charA.charCodeAt(0), j = charZ.charCodeAt(0);
    for (; i <= j; ++i) {
        a.push(String.fromCharCode(i));
    }
    return a;
  }
}


export * from './directives/permissions.directive';
export * from './auth.service';
export * from './user.service';

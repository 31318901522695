import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'limitSearch'
})
export class LimitSearchPipe implements PipeTransform {

  transform(items, active, limiter): unknown {
    if(!active){
      return items;
    }


    // console.log(limiter);


    let result = items.filter(item => item.subject_id==limiter)
    // console.log(result.length);

    return result;
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { QuestionTextPipe } from '../../../pipes/question-text.pipe';


@Component({
  selector: 'app-edit-fill-in',
  templateUrl: './edit-fill-in.component.html',
  styleUrls: ['./edit-fill-in.component.css']
})
export class EditFillInComponent implements OnInit {
  @Input() question;
  @Input() answers;


  @Output() updateQuestion = new EventEmitter<any>();
  @Output() updateAnswers = new EventEmitter();

  questionText_array = [];
  questionTextEdit = true;
  questionText = "";

  constructor() { }

  ngOnInit(): void {

    if(this.question.questionText){
      this.updateTextArray();
      this.questionTextEdit = false;
    }
  }

  submitQuestionText(form){
    this.questionTextEdit = false;
    this.updateTextArray();
  }

  onAddField(index, form){
    const inputIndex = this.questionText_array.filter(x => x.type=="input").length+1;
    this.questionText_array.splice(index, 0, {value: "", type: "input", inputIndex: inputIndex});
    this.answers.push({input: inputIndex, value: ""});
    this.translateToJSON(form);
  }

  onDeleteField(index, inputIndex, form){
    this.questionText_array.splice(index, 1);
    let x=0;
    let answerIndex=0;
    this.answers.forEach(el => {
      if(el.input == inputIndex){
        answerIndex=x;
      }
      x++;
    })

    this.answers.splice(answerIndex, 1);
    this.translateToJSON(form);
  }


  updateTextArray(){
    const usedAnswers = []; //zisti kolko input ostalo po potvrdeni
    const indexToRemove = [];
    this.questionText_array = this.question.questionText.split(" ");
    // console.log(this.answers);

    let x = 0;
    this.questionText_array.forEach(word => {
      let type = "text";
      let value = word;
      let inputIndex = 0;

      if(word.search("input%")>=0){
        inputIndex = word.split("%")[1];
        const el = this.answers.find(x => x.input == inputIndex);
        value = el.value;

        type = "input";
        usedAnswers.unshift(this.answers[inputIndex-1]);
      }

      if(word == "" || word==" "){
        indexToRemove.unshift(x);
      }

      this.questionText_array[x] = {value: value, type: type, inputIndex: inputIndex};
      x++;
    });

    indexToRemove.forEach(index => {
      this.questionText_array.splice(index, 1);
    });

    //odstranit prebytocne odpovede
    this.answers = [];
    usedAnswers.forEach(i => {
      this.answers.push(i);
    });

  }


  translateToJSON(form){

    const parsedArray = this.questionText_array.map(word => {
      let returnValue = word.value
      if(word.type == "input"){
        returnValue = "input%"+word.inputIndex;
        const el = this.answers.find(x => x.input == word.inputIndex);
        el.value = form.value[word.inputIndex];
      }

      return returnValue;
    });


    this.question.questionText = parsedArray.join(" ");

    this.updateQuestion.emit(this.question);
    this.updateAnswers.emit(this.answers);

    this.updateTextArray()

  }


    //Image stuff
    onImageChange(e) {
      this.question.material_source = e;
      this.updateQuestion.emit(this.question);
    }

}

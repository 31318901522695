
<div class="row">
  <form class="row g-3 col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1" [formGroup]="registrationForm" autocomplete="off">
    <div class="alert col-12" [innerHTML]="response.message" [ngClass]="{'alert-danger': response.status != 200, 'alert-success': response.status == 200}" role="alert" *ngIf="response">
    </div>
    <div class="col-md-6">
      <label for="inputFirstName" class="form-label">Meno:</label>
      <input type="text" class="form-control" id="inputFirstName" formControlName="first_name"
        [class.is-invalid] = "registrationForm.controls['first_name'].invalid && registrationForm.controls['first_name'].touched"
        [class.is-valid] = "registrationForm.controls['first_name'].valid && registrationForm.controls['first_name'].touched"
      >
      <div class="invalid-feedback" *ngIf="registrationForm.controls['first_name'].invalid && registrationForm.controls['first_name'].touched">
        Prosím zadajte svoje meno.
      </div>
    </div>
    <div class="col-md-6">
      <label for="inputLastName" class="form-label">Priezvisko:</label>
      <input type="text" class="form-control" id="inputLastName" formControlName="last_name" required
        [class.is-invalid] = "registrationForm.controls['last_name'].invalid && registrationForm.controls['last_name'].touched"
        [class.is-valid] = "registrationForm.controls['last_name'].valid && registrationForm.controls['last_name'].touched"
      >
      <div class="invalid-feedback" *ngIf="registrationForm.controls['last_name'].invalid && registrationForm.controls['last_name'].touched">
        Prosím zadajte svoje priezvisko.
      </div>
    </div>

    <div class="col-md-12">
      <label for="inputEmail" class="form-label">Email:</label>
      <input type="email" class="form-control" id="inputEmail" formControlName="email" required autocomplete="off"
        [class.is-invalid] = "registrationForm.controls['email'].invalid && registrationForm.controls['email'].touched"
        [class.is-valid] = "registrationForm.controls['email'].valid && registrationForm.controls['email'].touched"
      >
      <div class="invalid-feedback" *ngIf="registrationForm.controls['email'].invalid && registrationForm.controls['email'].touched">
        Prosím zadajte svoj platný email.
      </div>
    </div>
    <div class="col-md-12">
      <label for="inputSecret" class="form-label">ID kód:</label>
      <input type="password" class="form-control" id="inputSecret" formControlName="secret" required
      [class.is-invalid] = "registrationForm.controls['secret'].invalid && registrationForm.controls['secret'].touched"
      [class.is-valid] = "registrationForm.controls['secret'].valid && registrationForm.controls['secret'].touched"
      >
      <div class="invalid-feedback" *ngIf="registrationForm.controls['secret'].invalid && registrationForm.controls['secret'].touched">
        <!-- Prosím zadajte svoje heslo k portálu EduPage. -->
        Prosím zadajte svoj ID kód.

      </div>

    </div>
    <div class="col-md-12">
      <label for="inputPassword" class="form-label">Nové heslo:</label>
      <input type="password" class="form-control" id="inputPassword" formControlName="password" required
      [class.is-invalid] = "registrationForm.controls['password'].invalid && registrationForm.controls['password'].touched"
      [class.is-valid] = "registrationForm.controls['password'].valid && registrationForm.controls['password'].touched"
      >
      <div class="invalid-feedback" *ngIf="registrationForm.controls['password'].invalid && registrationForm.controls['password'].touched">
        Zadajte nové heslo: musí obsahovať minimálne 5 znakov.
      </div>

    </div>
    <div class="col-md-12">
      <label for="inputPasswordCheck" class="form-label">Zopakujte heslo:</label>
      <input type="password" class="form-control" id="inputPasswordCheck" formControlName="passwordCheck" required
      [class.is-invalid] = "registrationForm.controls['passwordCheck'].invalid && registrationForm.controls['passwordCheck'].touched"
      [class.is-valid] = "registrationForm.controls['passwordCheck'].valid && registrationForm.controls['passwordCheck'].touched"
      >
      <div class="invalid-feedback" *ngIf="registrationForm.controls['passwordCheck'].invalid && registrationForm.controls['passwordCheck'].touched">
        Heslá sa musia zhodovať.
      </div>

    </div>
    <div class="col-12">
      <button type="submit" class="btn btn-primary" (click)="registerUser()" [disabled]="registrationForm.invalid">Registrovať</button>
    </div>


  </form>



</div>

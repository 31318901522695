import { Component, OnInit, Pipe, PipeTransform, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubjectsService } from '../services/subjects.service';
import { UntypedFormBuilder } from '@angular/forms';
import { PermissionsDirective, AuthService } from '../services/global.service';
import { map, tap, flatMap } from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.css']
})
export class LessonsComponent implements OnInit {

  public lessons = [];
  subjectId;
  subjectName;
  classes = [];
  unset_classes = [];
  groups = [];
  leftOutLessons = [];

  lessonForm = this.formBuilder.group({
    name: '',
    group_id: '',
    group_name: '',
    list_order: '',
    subjectId: ''
  });

  subjectForm;

  groupForm = this.formBuilder.group({
    id: '',
    name: '',
    subjectId: ''
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subjectsSrvc: SubjectsService,
    private formBuilder: UntypedFormBuilder,
    private auth: AuthService,
    private elementRef: ElementRef,
  ) {

    let routeData = this.route.snapshot.data
    console.log(routeData);

    if(routeData.subjectName) {
      this.subjectName = routeData.subjectName
    }
  }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.subjectId = Number(routeParams.get('subjectId'));

    // this.subjectsSrvc.getSingleSubject(this.subjectId)
    //   .subscribe(data => this.subject = data);
    this.loadLessons();

  }

  loadLessonForm(group: any) {
    this.lessonForm.patchValue({group_id: group.id, group_name: this.groups.find(g => g.id == group.id).name,list_order: group.lessons.length+1});
  }

  loadGroupForm(group: any) {
    this.groupForm.patchValue(group);
  }

  onSubmit() {
    //Nastavi sa subjectId pre vytvorenie lekcie
    this.lessonForm.patchValue({subjectId: this.subjectId});

     //Vytvorit novu lekciu
    this.subjectsSrvc.createLesson(this.lessonForm.value)
      .subscribe(data => {
        console.log(data);
        this.loadLessons();
      });
  }

  onDelete(lessonId){
    this.subjectsSrvc.deleteLesson(lessonId)
      .subscribe(data => {
        // console.log(data);
        this.loadLessons();
      });
  }

  loadLessons(){

    this.lessons = [];
    this.classes = [];
    this.groups = []
    this.subjectsSrvc.getLessons(this.subjectId)
      .subscribe(lessons => {
        this.subjectsSrvc.getLessonsGroups(this.subjectId).subscribe((groups:any) => {
          if(!lessons.length) {
            lessons = []
          }

          this.leftOutLessons = lessons.filter(lesson => lesson.group_id == 0)

          if(groups.message) {
            return
          }

          this.groups = groups.sort((a,b) => {

              const prefixA = parseInt(a.name.split('.')[0])
              const prefixB = parseInt(b.name.split('.')[0])

              if (prefixA && prefixB) {
                if(prefixA < prefixB) { return -1; }
                if(prefixA > prefixB) { return 1; }
              }

              if(a.name < b.name) { return -1; }
              if(a.name > b.name) { return 1; }
              return 0;
          })


          groups.forEach(group => {
            group.lessons = lessons.filter(lesson => lesson.group_id == group.id)
          });

        })
      })
  }

  getLessonsByClass(classId){
    let value = this.lessons;
    let result = value.filter(lesson => lesson.class_id == classId);

    return result;
  }

  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      let classId = event.container.element.nativeElement.getAttribute('id')
      let lessonId = event.item.element.nativeElement.getAttribute('id')

      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
    const lessonsToUpdate = this.sort(event.container.data).map(x => {
      return {
        id: x.id,
        group_id: event.container.id,
        list_order: x.list_order
      };
    })
    console.log(lessonsToUpdate);

    this.subjectsSrvc.updateLesson({lessons: lessonsToUpdate}).subscribe(res => {
      console.log(res);
    })

  }

  sort(list: any[]): any[] {
    let index = 1
    list.forEach(item => {
      item.list_order = index
      index += 1
    });

    return list

  }

  noReturnPredicate() {
    return false;
  }

  newGroup() {
    //Nastavi sa subjectId pre vytvorenie lekcie
    this.groupForm.patchValue({subjectId: this.subjectId});

     //Vytvorit novu lekciu
    this.subjectsSrvc.createLessonGroup(this.groupForm.value)
      .subscribe(data => {
        console.log(data);
        this.groupForm.reset()
        this.loadLessons();
      });

  }

  updateGroup() {
    console.log(this.groupForm.value);

    this.subjectsSrvc.updateLessonGroup(this.groupForm.value).subscribe(res => {
      console.log(res);
      this.groupForm.reset()
      this.loadLessons();
    })

  }

  deleteGroup(id: number) {
    this.subjectsSrvc.deleteLessonGroup(id).subscribe(res => {
      console.log(res);
      this.loadLessons();
    })
  }

  // Edit subject

  loadSubjectForm() {
    this.subjectForm = this.formBuilder.group({
      name: this.subjectName,
      subjectId: this.subjectId
    })
  }

  upadeteSubject() {


    this.subjectsSrvc.updateSubject(this.subjectForm.value).subscribe(
      res => {
        console.log(res);
      }
    )

  }

  deleteSubject() {
    if(!confirm("Určite si želáte zmazať predmet '" + this.subjectName+ "'")) return


    this.subjectsSrvc.deleteSubject(this.subjectId).subscribe(
      res => this.router.navigate(['/subjects/'])
    )

  }
}


@Pipe({
  name: 'filterLessons'
})
export class FilterLessonsPipe implements PipeTransform {


  transform(value: any, classId: any): any {

    let result = value.filter(lesson => lesson.class_id == classId);
    result['count'] = result.length;
    console.log(result);


    return result;

    }

  }

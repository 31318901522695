import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormArray, Validators, UntypedFormGroup  } from "@angular/forms";

@Component({
  selector: 'app-question-mark-correct',
  templateUrl: './question-mark-correct.component.html',
  styleUrls: ['./question-mark-correct.component.css']
})
export class QuestionMarkCorrectComponent implements OnInit {

  @Input() question;
  @Input() results;
  @Output() updateEmit = new EventEmitter<any>();

  form: UntypedFormGroup;


  constructor(
    private formBuilder: UntypedFormBuilder

  ) {
    this.form = this.formBuilder.group(
      {
      answers: this.formBuilder.array([], [Validators.required])
    })
  }

  onCheckboxChange(e) {
    const answer: UntypedFormArray = this.form.get('answers') as UntypedFormArray;

    if (e.target.checked) {
      answer.push(new UntypedFormControl(e.target.value));
    } else {
       const index = answer.controls.findIndex(x => x.value === e.target.value);
       answer.removeAt(index);
    }

    this.updateEmit.emit(answer.value);
  }

  ngOnInit(): void {
    if(this.results){
      this.loadResults();
    }
  }


  loadResults(){

  }

}



<div class="container col-lg-8 offset-lg-2">

<h4>Vitajte na naštuduj.sk!</h4>

<!-- 
  <div class="card text-center mt-5 ">
    <div class="card-header" style="background-color: #ffe3af">
      <h5>Upozornenie!</h5>
    </div>
    <div class="card-body">
      <h6 class="card-title">Systém je vo fáze testovania a preto sa môžu vyskytnúť chyby!</h6>
      <p class="card-text">V prípade, že narazíte na chybu alebo nezvyčajné správanie aplikácie, prosím, dajte nám o tom vedieť.</p>
      <a href="mailto:admin@nastuduj.sk"  target="_blank" class="fs-5">admin@nastuduj.sk</a>
      <p class="card-text fw-bold mt-4">Aplikácia momentálne nepodporuje všetky funkcie pre mobilné zariadnia!</p>

    </div>
  </div> -->



  <div class="card text-center mt-5 ">
    <div class="card-header" style="background-color: #d8ede9">
      <h5>Začíname!</h5>
    </div>

    <div class="card-body">
      <h5 class="card-title">Využi náš systém a uč sa kedykoľvek a kďekoľvek!</h5>
      <p class="card-text">Knižnica poznania už čaká na Teba...</p>
      <a href="#" class="btn btn-primary" [routerLink]="['/subjects']">Začni teraz!</a>
    </div>


    <!-- <div class="card-footer text-muted">
      využi 233 lekcií vo viac ako 30 predmetoch
    </div> -->
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router  } from '@angular/router';
import { SubjectsService } from '../services/subjects.service';
import { PermissionsDirective } from '../services/global.service';
import { UntypedFormBuilder } from '@angular/forms';
import { UserService } from '../services/user.service';
import { TestService } from '../services/test.service';

@Component({
  selector: 'app-lesson-detail',
  templateUrl: './lesson-detail.component.html',
  styleUrls: ['./lesson-detail.component.css']
})
export class LessonDetailComponent implements OnInit {
  subject;
  lesson;
  errorMsg = false;
  editLessonName = false;

  visibleTests = []
  hiddenTests = []


  lessonForm = this.formBuilder.group({
    name: '',
    group_id: '',
    group_name: '',
    list_order: '',
    subjectId: ''
  });


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private subjectsSrvc: SubjectsService,
    private formBuilder: UntypedFormBuilder,
    public user: UserService,
    private testSrvc: TestService
  ) { }

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;

    this.route.params.subscribe(
      routeParams => {
        const lessonId = Number(routeParams.lessonId);
        const subjectId = Number(routeParams.subjectId);

        this.loadSubject(subjectId); //Nacita subject
        this.loadLesson(subjectId,lessonId); //nacita lesson
      });
  }

  loadSubject(subjectId){
    //Načítanie dát k predmetu 'subject'
    this.subjectsSrvc.getSingleSubject(subjectId)
      .subscribe(
        data => {this.subject = data},
        error => {console.log(error);},
        () => {
        }
      );
  }

  loadLesson(subjectId, lessonId){
    //Načítanie dát k lekcií 'lesson'
    this.subjectsSrvc.getSingleLesson(lessonId)
      .subscribe(
        data => { //next - keď sa objavia dáta
          if(data.error){
            this.errorMsg = true;
            alert(data.error)
            this.router.navigate(['/subjects', subjectId]);
            return
          }
            this.lesson = data
            data.testId.forEach(test => {
              if(!test.name){
                test.name = data.name
              }
              test.status = this.testSrvc.getTestStatus(test)
            });


            this.visibleTests = data.testId.filter(t => t.settings.visible)
            this.hiddenTests = data.testId.filter(t => !t.settings.visible)
        },
        error => {console.log(error)},
        () => { // when complete

        }
      );
  }

  updateTestVisibility(test: any, e: any) {
    if(e.target.checked) {
      if(!confirm("Skutočne chcete sprístupniť test?")) {
        e.target.checked = false
        return
      }
    }
    test.settings.visible = e.target.checked

    this.testSrvc.updateTest(test).subscribe(res => console.log(res))

  }

  updateLesson(){
    const data = {
      "lessonId": this.lesson.id,
      "name": this.lessonForm.value.name
    }
    this.subjectsSrvc.updateLesson(data)
      .subscribe(res => {
        console.log(res);
        this.lesson.name = data.name
      })
  }

  loadLessonForm(lesson: any) {
    this.lessonForm.patchValue(this.lesson);
  }

  deleteLesson(){

    if(confirm("Určite chcete vymazať lekciu?")){
      this.subjectsSrvc.deleteLesson(this.lesson.id)
        .subscribe(data => {
          this.router.navigate(['/subjects/'+this.lesson.subjectId]);
        });
    }

  }


}

import {NgModule, Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges} from '@angular/core';
import {YouTubePlayerModule} from '@angular/youtube-player';

import { FileHandlerService } from '../../../services/file-handler.service';


let apiLoaded = false;


@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit, OnChanges {

  @Input() video;

  @ViewChild('player') player: any;

  videoId;

  constructor(
    private fileHandler: FileHandlerService
  ){

  }

  ngOnInit() {
    this.video = {name: '', path: ''}
    if (!apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      apiLoaded = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.video.currentValue){
      this.videoId = this.fileHandler.getVideoId(changes.video.currentValue.path)

    }

  }
  // Autoplay
  onReady(e) {

  }

}

import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDndUpload]'
})
export class DndUploadDirective {

  constructor() { }

  @Output() status = new EventEmitter();

  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();

    this.status.emit(true);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    console.log("leave");

    this.status.emit(false);

  }

  @HostListener('change', ['$event']) public onClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();


  }
}

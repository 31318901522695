<nav id="sideMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
  <div class="position-sticky pt-3">
    <h5 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
    </h5>
    <ul class="nav flex-column">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/']">
          <i class="bi bi-compass"></i>
          Domov</a>
      </li>
      <!-- <li class="nav-item" *grantPermissions="'guest'">
        <a class="nav-link" href="/register" [routerLink]="['/register']">
          <i class="bi bi-gear"></i>
            Registrácia</a>
      </li> -->
      <li class="nav-item" *grantPermissions="'allUsers'">
        <a class="nav-link" href="/settings" [routerLink]="['/preferences']">
          <i class="bi bi-gear"></i>
            Nastavenia</a>
      </li>
    </ul>

    <div *grantPermissions="'admin teacher student'">
        <!-- <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">Študent</h6> -->
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" routerLink="subjects">
              <i class="bi bi-lightbulb"></i>
              Lekcie
            </a>
          </li>
        </ul>
      </div>
      <div class="admin-section" *grantPermissions="'admin'">
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">Administrátor</h6>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" ui-sref="users" [routerLink]="['/users']">
              <i class="bi bi-people"></i>
              Používatelia
            </a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" ui-sref="users">
              <i class="fa fa-lock feather"></i>
              Administrácia</a>
          </li> -->
        </ul>
      </div>
  </div>
</nav>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'questionText'
})
export class QuestionTextPipe implements PipeTransform {


  transform(value: string): string {
    if(value){
      let text = value.split(' ');
      let indexToRemove = [];

      let x=0;
      text.forEach(word => {
        if(word.search("input%")>=0){
          indexToRemove.unshift(x);
          text[x] = ".......";
        }
        x++;
      });
      return text.join(" ");
    }

  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { shareReplay, tap, map, catchError } from 'rxjs/operators';
import * as moment from 'moment';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { ApiService } from '@services/api.service'

export interface ITokenValidator {
  message: string;
  code: number;
}

export interface IToken {
  data: {
    id: number;
    role: string;
    groups: [any];
  };
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    }),
    params: {}
  };

  private apiUrl= this.api.url

  private user = new BehaviorSubject<any>({});
  public userEmitter = this.user.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private api: ApiService
  ) {
    if(this.isLoggedIn()) {
      this.user.next(this.decodeCurrentToken())
    }
  }


  decodeCurrentToken(): IToken {
    let token = localStorage.getItem("id_token");

    if(token){
      try{
          return jwt_decode(token);
      }
      catch(Error){
          return null;
      }
    }

  }

  validateToken(): Observable<ITokenValidator> {
    return this.http.get<ITokenValidator>(this.apiUrl+'/validate_token.php');
  }

  login(email:string, password:string ) {
      return this.http.post<{message: string, status: number;}>(this.apiUrl+'/users/login.php', {email, password}, this.httpOptions)
          // this is just the HTTP call,
          // we still need to handle the reception of the token
          .pipe(
            tap(res => {
              console.log(res);

              if(res.status == 200){
                this.setSession(res);
                this.router.navigateByUrl('/');
              }else {
                this.router.navigateByUrl('/login?reason='+res.message);
              }
            }),
            shareReplay()
        );
  }

  setSession(authResult) {
      const expiresAt = moment().add(authResult.expiresIn,'second');

      localStorage.setItem('id_token', authResult.idToken);
      localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
      this.user.next(this.decodeCurrentToken())
  }

  logout(reason?: string) {
      localStorage.removeItem("id_token");
      localStorage.removeItem("expires_at");
      this.user.next({})

      if(reason){
        this.router.navigate(['/login'], { queryParams: { reason: reason } });
      }else{
        this.router.navigate(['/login']);
      }
  }

  public isLoggedIn() {
      if(moment().isBefore(this.getExpiration())){
        return true;
      }else {
        if(localStorage.getItem("expires_at")){
          this.logout("Platnosť vášho prihlásenia vypršala. Prosím prihláste sa znova!");
        }
        return false;
      }
  }

  isLoggedOut() {
      return !this.isLoggedIn();
  }

  getExpiration() {
      const expiration = localStorage.getItem("expires_at");
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
  }

  isRole(roles) {
    let current_role = this.decodeCurrentToken().data.role;

    if(roles.includes(current_role)){
      return true;
    }
  }

  userId(): number {
    return this.decodeCurrentToken().data.id;
  }
}

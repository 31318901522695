<div>

  <!-- Image -->
  <app-image-upload [material_source]="question.material_source" [question_id]="question.id" (updateMaterialSource)="onImageChange($event)"></app-image-upload>

  <form *ngIf="!questionTextEdit" #fillIn="ngForm" class="row row-cols-lg-auto align-items-center">
    <div class="col-auto fillIn-list" *ngFor="let word of questionText_array; let i = index">

      <h5 *ngIf="word.type!='input'">
        <a class="add-button" (click)="onAddField(i, fillIn)" *ngIf="i == 0">+ </a>
        {{word.value}}
        <a class="add-button" (click)="onAddField(i+1, fillIn)" >+</a></h5>

      <div class="input-group" *ngIf="word.type=='input'">
        <input  class="form-control form-control-sm" type="text"  (change)="translateToJSON(fillIn)" [name]="word.inputIndex" [(ngModel)]="word.value">
        <button class="btn btn-danger btn-sm" (click)="onDeleteField(i, word.inputIndex,fillIn)">- </button>
      </div>

    </div>

     <div class="col-auto">
       <button type="button" class="btn btn-warning" (click)="questionTextEdit=true;translateToJSON(fillIn)">Upravit</button>
     </div>
  </form>

  <form *ngIf="questionTextEdit" #questionEdit="ngForm" (submit)="submitQuestionText(questionEdit)">
    <div class="input-group">
      <input type="text" class="form-control" name="questionText" [(ngModel)]="question.questionText"
      required  minlength="5" maxlength="300">
      <button type="submit" name="button" [disabled]="!questionEdit.valid" class="btn btn-success">Potvrdiť</button>
    </div>
  </form>
</div>

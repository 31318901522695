<div *ngIf="question && answers">
  <form class="input-group mb-3">
    <span class="input-group-text">Otázka: </span>
    <input type="text" class="form-control" name="questionText" placeholder="Zadanie otázky" (change)="updateQuestion.emit(this.question)" [(ngModel)]="question.description">
  </form>

  <!-- Image -->
  <app-image-upload [material_source]="question.material_source" [question_id]="question.id" (updateMaterialSource)="onImageChange($event)"></app-image-upload>


    <div cdkDropList class="example-list col-sm-5" (cdkDropListDropped)="drop($event)" style="padding: 0">
      <div class="example-box" *ngFor="let item of orderList; index as i;" cdkDrag>
        {{i+1}}. {{item}}
        <button type="button" class="btn-close" (click)="deleteItem(i)"></button>
      </div>
    </div>

  <form #newItemForm="ngForm"(submit)="addItem(newItemForm)">
    <div class="input-group mt-2 row" >
      <input type="text" name="newItem" class="form-control col-sm-8" ngModel required>
      <button [disabled]="!newItemForm.valid" type="submit" name="button" class="btn btn-success col-sm-4">+</button>
    </div>
  </form>

</div>



<h3>Triedy</h3>



<div class="section p-4" *ngIf="classForm">

  <ng-container [formGroup]='classForm'>
    <h5 *ngIf="classForm.value.id">Úprava triedy {{classForm.value.name}}</h5>
    <h5 *ngIf="!classForm.value.id">Nová trieda</h5>

    <div class="row g-3" >
      <div class="col-lg-6">
        <label for="">Názov triedy</label>
        <input type="text" class="form-control" formControlName="name">
      </div>


      <div class="col-12 d-flex">
        <button type="button" name="button" class="btn btn-secondary  me-2" (click)="classForm = null">Zrušiť</button>
        <button type="button" name="button" class="btn btn-primary" *ngIf="classForm.value.id" (click)="updateClass()">Upraviť</button>
        <button type="button" name="button" class="btn btn-primary" *ngIf="!classForm.value.id" (click)="createClass()">Vytvoriť</button>

        <button type="button" name="button" *ngIf="classForm.value.id" (click)="deleteClass(classForm.value)" class="btn btn-danger ms-auto ">Vymazať</button>


      </div>
    </div>

  </ng-container>

</div>

<div class="data-table my-5">


  <table class="table table-hover">
    <thead>
      <tr>
        <th>ID</th>
        <th>Názov</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let class of classes" class="clickable" (click)="loadClassForm(class)">
        <td>{{class.id}}</td>
        <td>{{class.name}}</td>
      </tr>
    </tbody>
  </table>

  <button type="button" name="button" class="btn btn-primary" (click)='loadClassForm()'>Vytvoriť triedu</button>
</div>

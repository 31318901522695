import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PreferencesComponent } from "./preferences/preferences.component";
import { SubjectsComponent } from "./subjects/subjects.component";
import { LessonsComponent } from "./lessons/lessons.component";

import { LoginComponent } from './user-forms/login/login.component';
import { RegistrationComponent } from './user-forms/registration/registration.component';

import { UsersComponent } from "./admin-panel/users/users.component";
import { ClassesComponent } from './admin-panel/classes/classes.component';


import { LessonDetailComponent } from "./lesson-detail/lesson-detail.component";
import { TestComponent } from "./lesson-detail/test/test.component";
import { TestEditorComponent } from "./lesson-detail/test-editor/test-editor.component";
import { TestResultsComponent } from "./lesson-detail/test-results/test-results.component";

import { UserAccessGuard } from "./guards/user-access.guard";
import { UserPermissionsGuard } from "./guards/user-permissions.guard";
import { GuestAccessGuard } from "@root/guards/guest-access.guard";

import { SubjectNameResolver } from "@services/resolvers/subject-name.resolver";
import { LessonNameResolver } from "@services/resolvers/lesson-name.resolver";

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'login', component: LoginComponent, canActivate: [GuestAccessGuard] },
  // { path: 'register', component: RegistrationComponent, canActivate: [GuestAccessGuard] },
  { path: 'preferences', component: PreferencesComponent, canActivate: [UserAccessGuard]},
  { path: 'users', component: UsersComponent, canActivate: [UserAccessGuard, UserPermissionsGuard], data:{ roles: ['admin']} },
  { path: 'classes', component: ClassesComponent, canActivate: [UserAccessGuard, UserPermissionsGuard], data:{ roles: ['admin']} },
  { path: 'subjects', component: SubjectsComponent, canActivate: [UserAccessGuard] },
  { path: 'subjects/:subjectId', component: LessonsComponent, canActivate: [UserAccessGuard], resolve: {subjectName: SubjectNameResolver} },
  { path: 'subjects/:subjectId/:lessonId', component: LessonDetailComponent, canActivate: [UserAccessGuard], resolve: {subjectName: SubjectNameResolver, lessonName: LessonNameResolver} },
  { path: 'subjects/:subjectId/:lessonId/test', component: TestComponent, canActivate: [UserAccessGuard] },
  { path: 'subjects/:subjectId/:lessonId/test-editor', component: TestEditorComponent, canActivate: [UserAccessGuard, UserPermissionsGuard], data:{ roles: ['admin', 'teacher']} },
  { path: 'subjects/:subjectId/:lessonId/test-results', component: TestResultsComponent, canActivate: [UserAccessGuard]},
  { path: '**', redirectTo: ''}


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

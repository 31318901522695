import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-result-detail',
  templateUrl: './result-detail.component.html',
  styleUrls: ['./result-detail.component.css']
})
export class ResultDetailComponent implements OnInit, OnChanges {
  constructor() { }


  @Input() data = {
    questions: {},
    results: [],
    score: 0
  };


  questions;


  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges){
    // console.log(changes.currentValue);
    // console.log(this.data.questions);
    if(changes.data.currentValue){
      this.questions = Object.values(this.data.questions);
      console.log(this.data.results);


      let score = 0;
      let resultsArr = Object.values(this.data.results);
      resultsArr.forEach(element => {
        score += element.percent;
      });

    }

  }
}

<form [formGroup]="form">
  <div class="form-check option" *ngFor="let option of question.options">
    <input class="form-check-input" type="checkbox" *ngIf="results" [value]="option.key" [disabled]="results" [checked]="results[question.id].incorrect.includes(option.key) || results[question.id].correct.includes(option.key)">
    <input class="form-check-input" type="checkbox" *ngIf="!results" (change)="onCheckboxChange($event)" [value]="option.key">
    <label>{{option.value}}</label>
    <label class="form-check-label" *ngIf="results">
      <i style="color: orange" *ngIf="results[question.id].missing.includes(option.key)">Chýba</i>
      <i style="color: green" *ngIf="results[question.id].correct.includes(option.key)">Správne</i>
      <i style="color: red" *ngIf="results[question.id].incorrect.includes(option.key)">Nesprávne</i>
    </label>
  </div>
</form>

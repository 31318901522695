
<div class="container">
  <!-- <app-breadcrumb></app-breadcrumb> -->

</div>


<h5 *ngIf="lesson && errorMsg">{{lesson.error}}</h5>


<div class="container mt-5" *ngIf="!errorMsg && subject && lesson">


  <nav class="navbar navbar-light bg-light mb-5">
    <div class="container-fluid">
      <h3 class="py-3 m-0"><a [routerLink]="['/subjects', subject.id]">{{subject.name}}</a> <i class="bi bi-chevron-right px-2"></i> {{lesson.name}}</h3>
      <ng-container *grantPermissions="'admin teacher'">
        <span class="fw-normal heading-icon edit-text ms-auto" data-bs-toggle="modal" data-bs-target="#editLessonModal" (click)="loadLessonForm()" title="Upraviť">
          <i class="bi bi-pencil-fill"></i>
        </span>
        <span class="fw-normal heading-icon delete-text ms-3" (click)="deleteLesson()" title="Odstrániť lekciu">
          <i class="bi bi-trash-fill"></i>
        </span>
      </ng-container>
    </div>
  </nav>


  <!-- MATERIALS -->
  <h4 class="mt-3">Knižnica materiálov</h4>
  <div *ngIf="!errorMsg && subject && lesson">
    <app-lesson-materials [lesson]="lesson"></app-lesson-materials>
  </div>

  <!-- <h4 class="mt-3">Pracovný list</h4>
  <div *ngIf="!errorMsg && subject && lesson">
    <div >
      <p>Zatiaľ nebol vytvorený žiadny pracovný list k tejto lekcií.
      <button type="button" class="btn btn-success" name="button" *grantPermissions="'admin teacher'">Pridať pracovný list +</button>
    </div>
  </div> -->

  <!-- TEST -->
  <h4 class="mt-5">Testy</h4>
  <div *ngIf="!errorMsg && subject && lesson">
    <div>
      <table class="table" *ngIf="!((visibleTests.length == 0 && !user.isTeacher()) || (lesson.testId.length == 0 && user.isTeacher()))" >
        <thead>
          <tr>
            <th>Názov</th>
            <th>Status</th>
            <th  *grantPermissions="'admin teacher'"></th>
            <th  *grantPermissions="'admin teacher'"></th>
            <th  *grantPermissions="'admin teacher'"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let test of lesson.testId">
            <tr *ngIf="(user.isTeacher() && !test.settings.visible) || test.settings.visible" [class.hidden]="!test.settings.visible">
              <td><span class="text-link-hover" [routerLink]="['/subjects', subject.id, lesson.id, 'test', {testId: test.id, lessonName: lesson.name}]" >{{test.name}}</span></td>
              <td>{{test.status}}</td>
              <td *grantPermissions="'admin teacher'"><span class="text-link" [routerLink]="['/subjects', subject.id, lesson.id, 'test-results', {testId: test.id, testName: test.name}]">výsledky</span></td>
              <td *grantPermissions="'admin teacher'"><span class="text-link" [routerLink]="['/subjects', subject.id, lesson.id, 'test-editor', {testId: test.id}]">upraviť</span></td>
              <td *grantPermissions="'admin teacher'">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" [checked]="test.settings.visible" (click)="updateTestVisibility(test, $event)">
                  <label class="form-check-label">Prístupné</label> 
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div *ngIf="(visibleTests.length == 0 && !user.isTeacher()) || (lesson.testId.length == 0 && user.isTeacher())">
        Žiadne dostupné testy.
      </div>

      <div class="">
        <button *grantPermissions="'admin teacher'" type="button" class="btn btn-success d-block w-50 mx-auto" name="button" [routerLink]="['/subjects', subject.id, lesson.id, 'test-editor']">+</button>
      </div>


</div>




<div class="modal fade" id="editLessonModal" tabindex="-1" aria-labelledby="editLessonModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editLessonModalLabel">Upraviť lekciu</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" [formGroup]="lessonForm">
        <div class="input-group mb-3 p-3">
          <span class="input-group-text">Názov: </span>
          <input type="text" class="form-control" formControlName="name" placeholder="Názov">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zrušiť</button>
        <button type="button" [disabled]="!lessonForm.controls.name.value" class="btn btn-success" data-bs-dismiss="modal" (click)="updateLesson()">Upraviť</button>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import { TestService } from "../../services/test.service";
import { AuthService } from "@services/auth.service";

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit, OnDestroy {
  subjectId;
  timeLimit;
  showResults = false;

  testReadyTime = "";
  finishText = "";
  testBeginTime;

  testTimer;  

  testReady = false;
  testRun = false;
  testFinish = false;
  testReview = false;

  clientMessage = {
    timeRestriction: {
      time: ''
    },
    roundsRestriction: {
      rounds: 0
    }

  }

  info;
  testData;
  answersData = {};
  results;
  touched = false;
  questionsObject = {};

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private testSrvc: TestService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    // this.info = this.location.getState();

    //Read data from URL
    this.route.params.subscribe(
      routeParams => {
        const lessonId = Number(routeParams.lessonId);
        const subjectId = Number(routeParams.subjectId);
        this.info = routeParams
        this.subjectId = subjectId;


        if(!routeParams.testId){
          this.router.navigate(['/subjects/'+subjectId+"/"+lessonId]);
        }else{
          this.testSrvc.getTest(routeParams.testId)
            .subscribe(data => {
              this.testData = data;
              this.checkTest()
            });
        }
      });
  }

  //Check if ready
  checkTest(){
    let settings = this.testData.settings;
    console.log(settings);

    if(!settings.hasOwnProperty('showResults')) {
      this.testReady = true;
      this.showResults = false;
      // return
    }else {
      this.showResults = settings.showResults;

    }


    let currentTime = new Date();
    let startTime = new Date(Date.parse(settings.startTime));
    let endTime = new Date(Date.parse(settings.endTime));




    if(currentTime < startTime || currentTime > endTime){
      this.testReady = false;
      if(currentTime < startTime) {
        this.clientMessage.timeRestriction.time = startTime.getDate() + "." + (startTime.getMonth()+1) + "." + startTime.getFullYear() + " - " + startTime.getHours() + ":" + startTime.getMinutes();
      }
      return
    }

    if(settings.limitRounds){
      this.testSrvc.getUserTests(this.testData.id, this.auth.userId())
        .subscribe(res => {
          console.log(res);

          let user_tests_count = []
          if(!res.message) {
            user_tests_count = Object.values(res.data);
          }

          if(user_tests_count.length < settings.roundsLimit){
            this.testReady = true
          }else{
            this.clientMessage.roundsRestriction.rounds = user_tests_count.length;
          }
        })
    }else{
      this.testReady = true
    }

    if(settings.random && settings.randomCount){
      let questions = this.testData.questions;


      if(settings.randomCount < 1){
        settings.randomCount = 1;
      }

      
      const randomQuestions = []
      while(randomQuestions.length < settings.randomCount) {
        const randNumber = Math.floor(Math.random() * (settings.randomCount - 0) + 0)
        const randomQuestion = questions[randNumber];

        if(randomQuestions.find(q => q.id == randomQuestion.id)) {
          continue
        }

        randomQuestions.push(randomQuestion)
      }

      questions = randomQuestions

      this.testData.questions = questions;

      questions.forEach(element => {
        this.questionsObject[element.id] = element;
      });

    } else {
      this.testData.questions.forEach(element => {
        this.questionsObject[element.id] = element;
      });

    }

    if(settings.limitTime){
      this.timeLimit = settings.timeLimit;
    }

    if(this.auth.isRole('teacher') || this.auth.isRole('admin')){
      // this.testReady = true;
    }




  }

  runTest(){
    let settings = this.testData.settings;
    this.testBeginTime = Date.now();

    let resultjObj = {
      user_id: this.auth.userId(),
      test_id: this.testData.id,
      results: {},
      score: 0,
      time_begin:  this.testBeginTime,
      questions: this.questionsObject,
      ready: this.showResults,
      state: 'run'
    }

    console.log(resultjObj);
    this.testSrvc.sendResult(resultjObj).subscribe(
      res => console.log(res)
    )

    if(this.testReady) {

      this.testRun = true;
      if(settings.limitTime){

        let timeInSec = settings.timeLimit * 60;
        this.timeLimit = settings.timeLimit + ":00"

        this.testTimer = setInterval(() => {
          if(timeInSec > 1) {
            timeInSec--;
            let mins = Math.floor(timeInSec / 60);
            let secs = timeInSec % 60;
            this.timeLimit = this.str_pad_left(mins,'0',2) + ":" + this.str_pad_left(secs,'0',2);
          }else{
            this.finishTest("Čas na vypracovanie už vypršal!");
          }
        },1000)

      }

    }
  }

  str_pad_left(string,pad,length) {
      return (new Array(length+1).join(pad)+string).slice(-length);
  }

  updateItem(e, questionId){
    this.answersData[questionId] = e;
  }

  finishTest(reason): void{

    clearInterval(this.testTimer)

    this.testRun = false;
    this.testFinish = true;
    this.finishText = reason;


    this.testSrvc.evaluate(this.testData, this.answersData)
      .subscribe(results => {
        this.results = results.answers;
        console.log(results);

        let resultjObj = {
          user_id: this.auth.userId(),
          test_id: this.testData.id,
          results: results.answers,
          questions: this.questionsObject,
          score: results.score,
          time_begin: this.testBeginTime,
          time_finish:  Date.now(),
          state: 'finish'
        }

        this.results = resultjObj;

        this.testSrvc.sendResult(resultjObj).subscribe(
          res => console.log(res)
        )
      });
  }

  review(){
    this.testReview = true;
    // this.testFinish = false;

  }

  ngOnDestroy(): void {
    
    if(this.testTimer) {
      clearInterval(this.testTimer)
    }

  }
}

<!-- <header class="navbar navbar-expand-md mb-5 sticky-top">
  <div class="container-fluid">
    <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> -->
    <!-- <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">NaŠtuduj.sk</a> -->
    <!-- <span>{{auth.getExpiration()}}</span>

      <a *ngIf="auth.isLoggedIn()" class="d-flex" (click)="auth.logout('Boli ste úspešne odhlásený!')">Odhlásiť sa</a>
  </div>
</header>
 -->

<nav class="navbar navbar-expand-lg navbar-light bg-light mb-5 ">
  <div class="container-fluid">
    <span  class="navbar-brand fs-3" [routerLink]="['/']">naštuduj<i class="green-text">.sk</i></span>

    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarToggler">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <!-- <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li> -->
        <li class="nav-item" [class.dropdown]="item.children"  *ngFor="let item of subPages" >
          <a class="nav-link underline" *ngIf="!item.children" [class.active]='item.active' routerLink="{{item.label}}" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">{{item.name}}</a>
          <a class="nav-link underline" *ngIf="item.children" [class.dropdown-toggle]="item.children" [class.active]='item.active' data-bs-toggle="dropdown">{{item.name}}</a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown" *ngIf="item.children">
            <li *ngFor="let childItem of item.children"><a class="dropdown-item" routerLink="{{childItem.label}}" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">{{childItem.name}}</a></li>
          </ul>
        </li>
      </ul>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link underline" (click)="auth.logout('Boli ste úspešne odhlásený!')">Odhlásiť sa</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileHandlerService } from "../../../services/file-handler.service";


@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {
  @Input() material_source;
  @Input() question_id;

  @Output() updateMaterialSource = new EventEmitter<any>();

  imgFile;


  constructor(private fileHandler: FileHandlerService) { }

  ngOnInit(): void {
    if(this.material_source) {
      this.imgFile = "https://nastuduj.sk/storage/test_files//"+this.material_source;
    }
  }


  //Image stuff
  onImageChange(e) {
    const reader = new FileReader();
    if(e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imgFile = reader.result as string;
        this.fileHandler.upload_img({"imgSrc": reader.result})
        .subscribe(response => {
          this.updateMaterialSource.emit(response);
        })

      };
    }
  }

  onImageRemove(){
    this.imgFile = null;
    this.updateMaterialSource.emit();
  }

}

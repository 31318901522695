


<div class="container-fluid" cdkDropListGroup>


  <app-breadcrumb></app-breadcrumb>

  <div class=""  *grantPermissions="'admin teacher'">

  </div>


  <div class="mt-5" cdkDropListGroup>
      <h6 *ngIf="groups.length == 0" class="mb-4">Zatiaľ žiadne tématicke celky.</h6>
      <div class="accordion " id="accordionGroups" >
        <div class="accordion-item mb-3" *ngFor="let group of groups; let ind = index">
          <h2 class="accordion-header" id="groupHeading{{group.id}}" >
            <button class="accordion-button collapsed fs-5 " type="button" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#group'+group.id" aria-expanded="false" aria-controls="group{{group.id}}">
              <span class="fw-bold ">{{group.name}}</span>
              <ng-container *grantPermissions="'admin teacher'">
                <span class="fw-normal heading-icon edit-text ms-3" data-bs-toggle="modal" data-bs-target="#editGroupModal" (click)="loadGroupForm(group)" title="Upraviť">
                  <i class="bi bi-pencil-fill"></i>
                </span>
                <span class="fw-normal heading-icon add-text ms-3" data-bs-toggle="modal" data-bs-target="#newLessonModal" (click)="loadLessonForm(group)" title="Pridať lekciu">
                  <i class="bi bi-plus-circle-fill"></i>
                </span>
                <span class="fw-normal heading-icon delete-text ms-3" (click)="deleteGroup(group.id)" *ngIf="group.lessons.length == 0" title="Odstrániť skupinu">
                  <i class="bi bi-trash-fill"></i>
                </span>
              </ng-container>
            </button>
          </h2>
          <div id="group{{group.id}}" class="accordion-collapse collapse" >
            <div class="accordion-body p-0">
              <ul class="list-group list-group-flush" *ngIf="group.lessons.length > -100" [id]="group.id"
                cdkDropList
                [cdkDropListData]="group.lessons"
                (cdkDropListDropped)="drop($event)">

                <li cdkDrag class="list-group-item list-group-item-action d-flex justify-content-between align-items-start" *ngFor="let lesson of group.lessons; let i = index"
                [routerLink]="['/subjects', subjectId, lesson.id]" [id]="lesson.id">
                  <div class="ms-2 me-auto list-text-action" style="">
                    <b>{{lesson.list_order}}.</b>
                    {{lesson.name}}
                  </div>
                  <i class="bi bi-arrows-move move-icon fs-5 " cdkDragHandle *grantPermissions="'admin teacher'"></i>
                </li>

                <li class="list-group-item hide-hover" *ngIf="group.lessons.length == 0">Zatiaľ žiadne lekcie</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="accordion-item" *ngIf="leftOutLessons.length > 0">
          <h2 class="accordion-header" id="flush-headingThree">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="true" aria-controls="flush-collapseThree">
              <span class="fw-bold fs-5">Nezaradené lekcie</span>
            </button>
          </h2>
          <div id="flush-collapseThree" class="accordion-collapse collapse show" aria-labelledby="flush-headingThree" >
            <ul class="list-group list-group-flush" cdkDropList [cdkDropListData]="leftOutLessons" [cdkDropListEnterPredicate]="noReturnPredicate" id="list-unsorted">
              <li *ngFor="let lesson of leftOutLessons" class="list-group-item list-group-item-action d-flex justify-content-between align-items-start" [id]="lesson.id" cdkDrag [cdkDragData]="lesson">
                <div class="ms-2 me-auto">
                  <span *ngIf="lesson.list_order > 0">{{lesson.list_order}}</span>
                  <span class="list-text-action" [routerLink]="['/subjects', subjectId, lesson.id]">{{lesson.name}}</span>
                </div>
                <i class="bi bi-arrows-move move-icon fs-5 " cdkDragHandle *grantPermissions="'admin teacher'"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>


  </div>



  <div class="mt-4"  *grantPermissions="'admin teacher'">
    <button type="button" class="btn btn-secondary" data-bs-toggle="modal" (click)="loadSubjectForm()" data-bs-target="#editLessonModal">
      Upraviť predmet
    </button>

    <button type="button" class="btn btn-primary ms-3" data-bs-toggle="modal" data-bs-target="#newGroupModal">
      Vytvoriť nový tématický celok
    </button>

  </div>
</div>




<!-- Modals -->

<!-- New group -->
<div class="modal fade" id="newGroupModal" tabindex="-1" aria-labelledby="newGroupModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newGroupModalLabel">Nový tématický celok</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" [formGroup]="groupForm">
        <div class="input-group mb-3 p-3">
          <span class="input-group-text">Názov: </span>
          <input type="text" class="form-control" formControlName="name" placeholder="Názov">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zrušiť</button>
        <button type="button" [disabled]="!groupForm.controls.name.value" class="btn btn-success" data-bs-dismiss="modal" (click)="newGroup()">Vytvoriť</button>
      </div>
    </div>
  </div>
</div>


<!-- Edit group name -->
<div class="modal fade" id="editGroupModal" tabindex="-1" aria-labelledby="editGroupModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="editGroupModalLabel">Upraviť tématický celok</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" [formGroup]="groupForm">
        <div class="input-group mb-3 p-3">
          <span class="input-group-text">Názov: </span>
          <input type="text" class="form-control" formControlName="name" placeholder="Názov">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zrušiť</button>
        <button type="button" [disabled]="!groupForm.controls.name.value" class="btn btn-success" data-bs-dismiss="modal" (click)="updateGroup()">Upraviť</button>
      </div>
    </div>
  </div>
</div>



<!-- Edit lesson -->
<div class="modal fade" id="editLessonModal" tabindex="-1" aria-labelledby="editLessonModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" *ngIf="subjectForm">
      <div class="modal-header">
        <h5 class="modal-title" id="editLessonModalLabel">Úprava predmetu</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" [formGroup]="subjectForm">
        <div class="input-group ">
          <span class="input-group-text">Nový názov: </span>
          <input type="text" class="form-control" formControlName="name" placeholder="Názov lekcie">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zrušiť</button>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="deleteSubject()">Odstrániť predmet</button>
        <button type="button" [disabled]="!subjectForm.value.name || subjectForm.value.name == subjectName" class="btn btn-primary" data-bs-dismiss="modal" (click)="upadeteSubject()">Upraviť názov</button>
      </div>
    </div>
  </div>
</div>


<!-- New lesson -->
<div class="modal fade" id="newLessonModal" tabindex="-1" aria-labelledby="newLessonModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newLessonModalLabel">Nová lekcia</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" [formGroup]="lessonForm">
        <div class="input-group ">
          <span class="input-group-text">Nová lekcia: </span>
          <input type="text" class="form-control" formControlName="name" placeholder="Názov lekcie">
        </div>
        <div class="mt-3">
           <span class="fs-6">Tématický celok: <b>{{lessonForm.controls.group_name.value}}</b></span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zrušiť</button>
        <button type="button" [disabled]="!lessonForm.controls.name.value" class="btn btn-success" data-bs-dismiss="modal" (click)="onSubmit()">Vytvoriť</button>
      </div>
    </div>
  </div>
</div>

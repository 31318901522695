<app-breadcrumb></app-breadcrumb>


<div class="my-5">
  <ul class="nav justify-content-center  fs-3">
    <li class="nav-item custom-nav-item" [class.active]="letter == currentLetter" *ngFor="let letter of alphabet" (click)="filterSubjects(letter)">
      <a class="nav-link" aria-current="page">{{letter}}</a>
    </li>
  </ul>
</div>


<div class="custom-letter">
  <hr>
  <h1>{{currentLetter}}</h1>
</div>

<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
  <div class="col fade-in" *ngFor="let subject of visibleSubjects; let elIndex = index;">
    <div class="card subject" [routerLink]="['/subjects', subject.id]">
      <div class="card-img-top">
        <svg class="bd-placeholder-img card-img-top" width="100%" height="80" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false">
          <rect width="100%" height="100%" [attr.fill]="subject.color"></rect>
          <text x="45%" y="50%" fill="white" dy=".5em" style="font: bold 30px sans-serif">{{subject.name | firstLetter}}</text>
        </svg>
      </div>
      <div class="card-body">
        <h5 class="card-title subject-name text-center">
          {{subject.name | uppercase}}</h5>
      </div>
    </div>
  </div>
  <div class="col fade-in"*grantPermissions="'admin teacher'">
    <div class="card subject">
      <div class="card-img-top" (click)="onSubmit()">
        <svg xmlns="http://www.w3.org/2000/svg"width="100%" height="80" fill="#28a745" class="bi bi-plus" viewBox="0 0 16 16">
          <path x="45%" y="50%" dy=".5em" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>

      </div>
      <div class="card-body">
        <form [formGroup]="subjectForm"  >
          <input type="text" class="form-control" formControlName="name" placeholder="Názov nového predmetu" required>
        </form>
      </div>
    </div>
  </div>
</div>



  <h4 *ngIf="subjects?.length == 0">Neexistujú zatiaľ žiadne predmety.</h4>

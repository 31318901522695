
<div class="fs-5" *ngIf="lesson">
    <a [routerLink]="['/subjects', subjectId, lesson.id]"><i class="bi bi-chevron-left"></i>spať k lekcii</a>
</div>

<div class="container" *ngIf="!testData.id || testData.questions">


  <h2 class="text-center" *ngIf="testData.id">Úprava testu <b>{{testData.name}}</b></h2>
  <h2  class="text-center" *ngIf="!testData.id">Nový test k lekcii <a [routerLink]="['/subjects', subjectId , lesson.id]">{{lesson.name}}</a></h2>


  <!-- Test Settings   -->
  <form [formGroup]="testSettingsForm" class="mt-5" *ngIf="questions">

    <fieldset class="row gy-3 mb-3 text-start">
      <!-- <legend class="col-form-label col-sm-2 pt-0">Radios</legend> -->
      <div class="col-sm-2 offset-sm-1">
        <label class="col-form-label">Názov testu: </label>
      </div>
      <div class="col-sm-8">
        <input type="text" class="form-control col" placeholder="Názov testu" [formControl]="testName">
      </div>
      <div class="col-sm-2 offset-sm-1">
        <label class="col-form-label">Popis: </label>
      </div>
      <div class="col-sm-8">
        <textarea type="text" class="form-control col" placeholder="Popis testu" [formControl]="testDescription"></textarea>
      </div>
      <div class="col-sm-2 offset-sm-1">
        <label class="col-form-label">Dostupné: </label>
      </div>
      <div class="col-sm-4 input-group">
        <div class="input-group-text">Od:</div>
        <input type="datetime-local" class="form-control" placeholder="Od" formControlName="startTime">
      </div>
      <div class="col-sm-4 input-group">
        <div class="input-group-text">Do:</div>
        <input type="datetime-local" class="form-control" placeholder="Od" formControlName="endTime">
      </div>
      <div class="col-sm-12 row mt-4">
        <div class="col-sm-6 offset-sm-1">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="switchLimitTime" formControlName="limitTime">
            <label class="form-check-label" for="switchLimitTime">Obmedziť čas na vypracovanie</label>
          </div>
        </div>
        <div class="col-sm-4">
          <input type="number" [class.is-invalid]="!testSettingsForm.controls.timeLimit.valid && testSettingsForm.controls.timeLimit.touched && testSettingsForm.controls.limitTime.value"
          class="form-control" id="timeLimitInput"  min="1" formControlName="timeLimit" placeholder="čas v minútach">
        </div>
      </div>

      <div class="col-sm-12 row mt-2">
        <div class="col-sm-6 offset-sm-1">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="switchRandom" formControlName="random">
            <label class="form-check-label" for="switchRandom">Vybrať náhodné otázky (max. {{questions.length}}): </label>
          </div>
        </div>
        <div class="col-sm-4">
          <input type="number" class="form-control" id="randomCountInput"
          [class.is-invalid]="!testSettingsForm.controls.randomCount.valid && testSettingsForm.controls.randomCount.touched && testSettingsForm.controls.random.value"
          formControlName="randomCount" [attr.max]="questions.length" min="1" placeholder="počet" >
        </div>
      </div>
      <div class="col-sm-12 row mt-2">
        <div class="col-sm-6 offset-sm-1">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" formControlName="limitRounds">
            <label class="form-check-label">Počet spustení: </label>
          </div>
        </div>
        <div class="col-sm-4">
          <input type="number" class="form-control"
          [class.is-invalid]="!testSettingsForm.controls.roundsLimit.valid && testSettingsForm.controls.roundsLimit.touched && testSettingsForm.controls.limitRounds.value"
          formControlName="roundsLimit" min="1" placeholder="počet" >
        </div>
      </div>
      <div class="col-sm-12 row mt-2">
        <div class="col-sm-6 offset-sm-1">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="switchShowResults" formControlName="showResults">
            <label class="form-check-label" for="switchShowResults">Ukázať výsledky po odoslaní</label>
          </div>
        </div>
      </div>
    </fieldset>



  </form>

<!-- accordion-flush -->
  <div  class="test-editor accordion " id="accordionExample">


    <ng-container *ngIf="questions.length > 0">
      <div *ngFor="let question of questions; let i = index" class="question accordion-item">
        <h4 class="accordion-header">
          <button class="accordion-button collapsed" type="button"
          data-bs-toggle="collapse" [attr.data-bs-target]="'#test'+question.id" aria-expanded="false" [attr.aria-controls]="question.id">
            <b>{{i+1}}. &nbsp;</b>
            {{question.description}}
            {{question.questionText | questionText}}&nbsp; <a class="text text-danger" (click)="deleteQuestion(i)">Odstranit</a>
          </button>
        </h4>

        <div id="test{{question.id}}" *ngIf="answers.length > 0" class="accordion-collapse collapse " [class.show]="i==0" >
          <div class="accordion-body">

            <app-edit-mark-correct
            [question]="question" [testAnswers]="answers[i].answers"
            (updateQuestion)="onQuestionUpdate($event, question.id)"
            (updateAnswers)="onAnswersUpdate($event, question.id)"
            *ngIf="question.type == 'markCorrect'"></app-edit-mark-correct>

            <app-edit-fill-in
            [question]="question" [answers]="answers[i].answers"
            (updateQuestion)="onQuestionUpdate($event, question.id)"
            (updateAnswers)="onAnswersUpdate($event, question.id)"
            *ngIf="question.type == 'fillIn'"></app-edit-fill-in>

            <app-edit-find-pair
            [question]="question" [answers]="answers[i].answers"
            (updateQuestion)="onQuestionUpdate($event, question.id)"
            (updateAnswers)="onAnswersUpdate($event, question.id)"
            *ngIf="question.type == 'findPair'"></app-edit-find-pair>

            <app-edit-find-order
            [question]="question" [answers]="answers[i].answers"
            (updateQuestion)="onQuestionUpdate($event, question.id)"
            (updateAnswers)="onAnswersUpdate($event, question.id)"
            *ngIf="question.type == 'findOrder'"></app-edit-find-order>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <form style="margin-bottom: 7rem;">
    <div class="option">
      <div class="input-group mt-5">
        <span class="input-group-text">Typ otázky:</span>
        <select class="form-select" [(ngModel)]="questionType" [ngModelOptions]="{standalone: true}">
          <option value="" selected disabled hidden>Vyberte typ otázky</option>
          <option value="{{item.key}}" *ngFor="let item of questions_list | keyvalue">
            {{item.key}}
          </option>
      </select>
      </div>
      <div class="d-grid gap-2 mt-2">
        <button type="button" name="button" class="btn btn-success" (click)="onQuestionAdd(questionType)">+</button>
      </div>
    </div>
  </form>


  <div class="row offset-md-3 col-md-9 offset-lg-2 col-lg-10 gy-2 fixed-footer overflow-hidden">
    <div class="col-sm-9">
      <button type="button" [disabled]="questions.length < 1 || !testSettingsForm.valid || !testName.valid" name="button" class="btn btn-primary d-block w-100" (click)="onTestSubmit()">Uložiť test</button>
    </div>
    <div class="col-sm-3">
      <button type="button"  name="button" class="btn btn-danger d-block w-100" (click)="deleteTest()">Vymazať</button>
    </div>
  </div>

</div>

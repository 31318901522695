import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { SubjectsService } from '../../../services/subjects.service';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit, OnChanges {

  @Input() user;
  classes;



  constructor(
    private subjectSrvc: SubjectsService,
    private userSrvc: UserService,
    ) { }

  ngOnInit(): void {
    this.subjectSrvc.getClasses().subscribe(
      res => this.classes = res
    )
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  onCheckChange(e) {
    let classId = this.classes[e.value].id;

    if(e.checked){
      this.user.groups.push(this.classes[e.value]);
      this.userSrvc.addToGroup(classId, this.user.id)
        .subscribe(res => {
          console.log(res);

        });

    }else{
      let index = this.user.groups.indexOf(this.classes[e.value]);
      this.user.groups.splice(index, 1);

      this.userSrvc.removeFromGroup(classId, this.user.id)
        .subscribe(res => {
          console.log(res);

        })

    }
  }

  isChecked(value){
    return this.user.groups.some(el => el.id == value.id);
  }

}

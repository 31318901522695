import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class ApiService {

  public url= "https://api.nastuduj.sk/api";
  // public url= "http://localhost/proscholaris/api/api";


  constructor() { }
}

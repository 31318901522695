import { Component, OnInit } from '@angular/core';
import { GlobalService, PermissionsDirective, AuthService } from '../services/global.service';



@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {


  constructor(
    public auth: AuthService,
  ) { }

  ngOnInit(): void {


  }

}
